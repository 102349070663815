import ReactOnRails from 'react-on-rails';
import Admin from '../src/React/Pages/Admin/Admin';
import AffiliatePortfolio from '../src/React/Pages/Dashboard/AffiliatePortfolio';
import BenefitOfferAdminModal from '../src/React/Pages/BenefitOfferAdminModal/BenefitOfferAdminModal';
import BusinessProposalPage from '../src/React/Pages/BusinessProposal/BusinessProposal';
import CancellationSurvey from '../src/React/Components/CancellationSurvey/CancellationSurvey';
import CaseStudyAdminModal from '../src/React/Pages/CaseStudyAdminModal/CaseStudyAdminModal';
import CircularProgress from '../src/React/Components/Icons/CircularProgress';
import EntrepreneurDashboard from '../src/React/Pages/Dashboard/EntrepreneurDashboard';
import FinancialChart from '../src/React/Pages/FinancialChart/FinancialChart';
import FindInvestorsPage from '../src/React/Pages/FindInvestors/FindInvestors';
import Gallery from '../src/React/Pages/Gallery/Gallery';
import Hero from '../src/React/Components/Hero/Hero';
import Inbox from '../src/React/Pages/Inbox/Inbox';
import InboxRevamp from '../src/React/Pages/InboxRevamp/InboxRevamp';
import IndustryList from '../src/React/Pages/IndustryList/IndustryList';
import InvestorCardTwig from '../src/React/Pages/InvestorCardTwig/InvestorCardTwig';
import InvestorDashboard from '../src/React/Pages/Dashboard/InvestorDashboard';
import InvestorsPage from '../src/React/Pages/Investors/Investors';
import MarqueeComponent from '../src/React/Pages/Marquee/Marquee';
import MyMatchesPage from '../src/React/Pages/MyMatches/MyMatches';
import NewsfeedAdmin from '../src/React/Pages/NewsfeedAdmin/NewsfeedAdmin';
import NewsfeedPage from '../src/React/Pages/Newsfeed/Newsfeed';
import Notification from '../src/React/Pages/Notification/Notification';
import ProposalCarousel from '../src/React/Pages/ProposalCarousel/ProposalCarousel';
import ProposalCarouselBlock from '../src/React/Components/ProposalCarouselBlock/ProposalCarouselBlock';
import ProposalSummaryTwig from '../src/React/Pages/ProposalSummaryTwig/ProposalSummaryTwig';
import StatesSection from '../src/React/Pages/States/States';
import VideoPlayer from '../src/React/Components/Newsfeed/VideoPlayer';

ReactOnRails.register({ Admin });
ReactOnRails.register({ AffiliatePortfolio });
ReactOnRails.register({ BenefitOfferAdminModal });
ReactOnRails.register({ BusinessProposalPage });
ReactOnRails.register({ CancellationSurvey });
ReactOnRails.register({ CaseStudyAdminModal });
ReactOnRails.register({ CircularProgress });
ReactOnRails.register({ EntrepreneurDashboard });
ReactOnRails.register({ FinancialChart });
ReactOnRails.register({ FindInvestorsPage });
ReactOnRails.register({ Gallery });
ReactOnRails.register({ Hero });
ReactOnRails.register({ Inbox });
ReactOnRails.register({ InboxRevamp });
ReactOnRails.register({ IndustryList });
ReactOnRails.register({ InvestorCardTwig });
ReactOnRails.register({ InvestorDashboard });
ReactOnRails.register({ InvestorsPage });
ReactOnRails.register({ MarqueeComponent });
ReactOnRails.register({ MyMatchesPage });
ReactOnRails.register({ NewsfeedAdmin });
ReactOnRails.register({ NewsfeedPage });
ReactOnRails.register({ Notification });
ReactOnRails.register({ ProposalCarousel });
ReactOnRails.register({ ProposalCarouselBlock });
ReactOnRails.register({ ProposalSummaryTwig });
ReactOnRails.register({ StatesSection });
ReactOnRails.register({ VideoPlayer });
