/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Doc from '../Components/Icons/Doc';
import Pdf from '../Components/Icons/Pdf';
import Ppt from '../Components/Icons/Ppt';
import Xls from '../Components/Icons/Xls';

export const defaultUKLocationId = 125;

// eslint-disable-next-line consistent-return
export const removeEntryFromArray = (array, index) => {
  if (index !== -1) {
    array.splice(index, 1);
    return array;
  }
};

// eslint-disable-next-line consistent-return
export const renderDocumentIcon = (fileName) => {
  if (fileName?.includes('.doc')) {
    return (
      <Doc
        classNames="w-full h-full object-center object-contain"
        fileName={fileName}
      />
    );
  }
  if (fileName?.includes('ppt') || fileName?.includes('pps')) {
    return (
      <Ppt
        classNames="w-full h-full object-center object-contain"
        fileName={fileName}
      />
    );
  }
  if (fileName?.includes('xl') || fileName?.includes('xls')) {
    return (
      <Xls
        classNames="w-full h-full object-center object-contain"
        fileName={fileName}
      />
    );
  }
  if (fileName?.includes('pd')) {
    return (
      <Pdf
        classNames="w-full h-full object-center object-contain"
        fileName={fileName}
      />
    );
  }
};

export const renderDocumentIconOld = (fileName) => {
  let icon = '';
  if (fileName.includes('.doc')) {
    icon = '/images/small/doc@2x.png';
  } else if (fileName.includes('ppt') || fileName.includes('pps')) {
    icon = '/images/small/ppt@2x.png';
  } else if (fileName.includes('xl') || fileName.includes('xls')) {
    icon = '/images/small/xls@2x.png';
  } else {
    icon = '/images/small/pdf@2x.png';
  }
  return icon;
};

export const renderBusinessAvatar = (businessName, classes, bgClass) => {
  return (
    <span
      className={`
        rounded-md
        uppercase
        text-center
        align-middle
        text-white
        ${classes || 'w-24 h-24'}
        inline-flex
        items-center
        justify-center
        ${bgClass || ''}
      `}
    >
      {businessName.split(' ')[0].split('')[0]}
    </span>
  );
};

export const renderUserAvatar = (name, classes, bgClass) => {
  let nameFirstLetter = '';
  let surnameFirstLetter = '';
  const numberOfWords = name?.trim().split(/\s+/).length;

  if (numberOfWords > 1) {
    nameFirstLetter = name?.split(' ')[0].split('')[0];
    surnameFirstLetter = name?.split(' ')[1].split('')[0];
  } else {
    nameFirstLetter = name?.split(' ')[0].split('')[0];
    surnameFirstLetter = name?.split(' ')[0].split('')[1]; // takes the second letter of the first name
  }
  return (
    <span
      className={`
        text-white
        rounded-full
        align-middle
        text-center
        ${classes || 'md:w-12 md:h-12 w-10 h-10'}
        ${bgClass || 'bg-gradient-to-b from-logo-yellow to-logo-orange'}
        uppercase
        inline-flex
        items-center
        justify-center
        md:text-base
        text-sm
      `}
    >
      {nameFirstLetter}
      {surnameFirstLetter}
    </span>
  );
};

// This method needs to have the same logic as the one Twig/AppExtension.php with the same name
export const roundUpInvestmentFigure = (figure) => {
  // eslint-disable-next-line default-case
  switch (true) {
    case figure <= 1000:
      return Math.round(figure / 100) * 100;
    case figure <= 10000:
      return Math.round(figure / 1000) * 1000;
    case figure <= 100000:
      return Math.round(figure / 5000) * 5000;
    case figure <= 500000:
      return Math.round(figure / 25000) * 25000;
    case figure <= 1000000:
      return Math.round(figure / 50000) * 50000;
    case figure <= 10000000:
      return Math.round(figure / 100000) * 100000;
  }
  return Math.round(figure / 1000000) * 1000000;
};

export const generateRangeFromArray = (array) => {
  const valuesForRange = ['min'];
  // eslint-disable-next-line no-unsafe-optional-chaining
  const step = Math.round(100 / (array?.length - 1));

  // eslint-disable-next-line no-unsafe-optional-chaining, no-plusplus
  for (let i = 1; i <= array?.length - 2; i++) {
    valuesForRange.push(i * step);
  }
  valuesForRange.push('max');

  const result = {};
  array?.forEach((element, index) => {
    result[valuesForRange[index]] = element;
  });

  return result;
};

export const detectTranslateXval = (element) => {
  const transformValue = element.style.transform;
  return transformValue.split(',')[0].split('(')[1];
};

export const formatUrl = (url) => {
  const formattedUrl = url
    ?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    ?.replace(/\/$/, '');
  return formattedUrl;
};

export const formatInvestorName = (fullName) => {
  let firstName = '';
  let lastNameFirstLetter;
  const numberOfWords = fullName?.trim().split(/\s+/).length;
  firstName = fullName?.split(' ')[0];
  if (numberOfWords > 1) {
    lastNameFirstLetter = fullName?.split(' ')[numberOfWords - 1].split('')[0];
  }
  const formattedName = lastNameFirstLetter
    ? `${firstName} ${lastNameFirstLetter}.`
    : firstName;
  return formattedName;
};

export const detectXOverflow = (element) => {
  let direction = '';
  const elementRect = element.getBoundingClientRect();
  const elementLeft = elementRect.left;
  const elementRight = elementRect.right;

  // eslint-disable-next-line no-undef
  const windowWidth = window.innerWidth;

  if (elementLeft < 0) {
    direction = 'left';
  } else if (elementRight > windowWidth) {
    direction = 'right';
  } else {
    direction = 'none';
  }

  return direction;
};

export const updateDropdownPlacement = (showDropdown) => {
  const updatePlacement = () => {
    if (showDropdown) {
      // eslint-disable-next-line no-undef
      const dropdowns = document.querySelectorAll('.react-dropdown-wrapper');
      const visibleDropdown = Array.from(dropdowns).find((element) => {
        return element.classList.contains('shown');
      });

      // eslint-disable-next-line no-undef
      if (window.innerWidth >= 768) {
        visibleDropdown?.classList?.remove('max-w-xxs');
        visibleDropdown?.classList?.remove(
          '-translate-x-1/2',
          'left-2',
          'right-8'
        );
        return;
      }

      const overflowSide = detectXOverflow(visibleDropdown);
      if (overflowSide === 'none') {
        return;
      }
      if (overflowSide === 'right') {
        visibleDropdown?.classList?.add(
          '-translate-x-1/2',
          'left-2',
          'right-8'
        );
        visibleDropdown?.classList?.remove('max-w-xxs');
      } else if (overflowSide === 'left') {
        visibleDropdown?.classList?.add('max-w-xxs');
        visibleDropdown?.classList?.remove(
          '-translate-x-1/2',
          'left-2',
          'right-8'
        );
      }
    }
  };

  // eslint-disable-next-line no-undef
  window.addEventListener('resize', updatePlacement);
  updatePlacement();
  return () => {
    // eslint-disable-next-line no-undef
    return window.removeEventListener('resize', updatePlacement);
  };
};

export const checkProtocol = (url) => {
  const urlPrefixes = ['http://', 'https://'];
  const urlHasProtocol =
    url.includes(urlPrefixes[0]) || url.includes(urlPrefixes[1]);

  const formattedUrl = urlHasProtocol ? url : `https://${url}`;

  return formattedUrl;
};

export const chartColors = {
  chartBlue: '#2f7afc',
  chartDarkBlue: '#35a2eb',
  chartLighterBlue: '#35a2ebcc',
  chartLighterGreen: '#6ee7b7',
  chartLighterGreenB: '#6ee7b7cc',
  chartLighterPink: '#ed7eebcc',
  chartLighterPurple: '#a78bfacc',
  chartLighterRed: '#f03547cc',
  chartLighterMint: '#3bd2cd',
  chartPink: '#ed7eeb',
  chartPurple: '#a78bfa',
  chartRed: '#f03547',
  chartMint: '#3bd2cd',
  transparent: '#00000000',
  white: '#fff'
};

export const getLastCharacter = (item) => {
  const itemAsString = item.toString();
  return itemAsString[itemAsString.length - 1];
};
