import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import LogoInput from './LogoInput';
import NewsfeedButton from '../Newsfeed/Button';
import { renderUserAvatar } from '../../Util/data';
import BannerInput from './BannerInput';

const OfferAdminModal = ({
  avatar,
  name,
  type,
  postOffer,
  posting,
  preFetchedOffer,
  sites,
  bgClass
}) => {
  const currentDate = new Date();
  const nextYearsDate = currentDate.setDate(currentDate.getDate() + 365);
  const dateOneYearFromNow = new Date(nextYearsDate);

  const regexForTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;

  const benefitOffer = JSON.parse(preFetchedOffer);

  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [offerDetails, setOfferDetails] = useState(null);
  const [howToRedeem, setHowToRedeem] = useState(null);
  const [link, setLink] = useState(null);
  const [terms, setTerms] = useState(null);
  const [expiryDate, setExpiryDate] = useState(
    regexForTime.exec(dateOneYearFromNow.toISOString())[0]
  );
  const unsortedSites = JSON.parse(sites);
  const allSites = unsortedSites.sort((a, b) => {
    const nameA = a?.topLocation?.name.toUpperCase();
    const nameB = b?.topLocation?.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const [selectedSites, setSelectedSites] = useState([]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    postOffer(
      logo,
      banner,
      title,
      description,
      offerDetails,
      howToRedeem,
      link,
      terms,
      expiryDate,
      selectedSites
    );
  };

  const updateTime = (time) => {
    setExpiryDate(time);
  };

  const hideModal = () => {
    // eslint-disable-next-line no-undef
    const modalWrapper = document.querySelector('.modal-wrapper');
    modalWrapper.classList.toggle('hidden');
  };

  useEffect(() => {
    if (benefitOffer) {
      setLogo(benefitOffer?.logo?.src);
      setBanner(benefitOffer?.banner?.src);
      setTitle(benefitOffer?.title);
      setDescription(benefitOffer?.description);
      setOfferDetails(benefitOffer?.details);
      setHowToRedeem(benefitOffer?.howToRedeem);
      setLink(benefitOffer?.redemptionLink);
      setTerms(benefitOffer?.termsAndConditions);
      setExpiryDate(benefitOffer?.expiredDate?.slice(0, -9));
      setSelectedSites([
        ...benefitOffer?.sitesCodes.map((site) => {
          return site.code;
        })
      ]);
    }
  }, []);

  const setOfferSite = (event) => {
    let selSites = selectedSites;
    if (event === '') {
      if (selectedSites.length === allSites.length) {
        selSites = [];
      } else {
        selSites = [];
        allSites.forEach((el) => {
          selSites.push(el.siteCode);
        });
      }
    } else if (selectedSites.includes(event)) {
      selSites = selectedSites.filter((site) => {
        return site !== event;
      });
    } else {
      selSites = selectedSites.concat(event);
    }
    setSelectedSites([...selSites]);
  };

  return (
    <div
      tabIndex="-1"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-screen justify-center items-center flex bg-black bg-opacity-90 md:pb-12 pb-14"
      role="dialog"
    >
      <div
        className="
          bg-white
          rounded-2xl
          py-6
          md:w-1/2
          xl:w-2/5
          w-11/12
          max-w-full
          h-auto
          m-auto
          md:mt-12
          mt-14
          flex
          flex-col
          gap-4
        "
      >
        <div className="flex items-center justify-between px-6">
          <h2 className="text-gray-800 font-semibold md:text-xl text-lg leading-8 mb-0">
            {type === 'add' ? 'Add' : 'Edit'} a perk/benefit
          </h2>
          <button
            type="button"
            onClick={hideModal}
            className="inline-flex items-center justify-center w-6 h-6"
          >
            <span className="material-icons text-gray-600">close</span>
          </button>
        </div>
        <div className="w-full py-2 md:px-6 px-4 flex items-start gap-6">
          {avatar ? (
            <img
              src={avatar}
              alt={name}
              className="w-16 h-16 flex-shrink-0 lg:inline-flex hidden rounded-lg object-cover object-center"
            />
          ) : (
            renderUserAvatar(
              name,
              'w-16 h-16 flex-shrink-0 lg:inline-flex hidden',
              `bg-${bgClass}`
            )
          )}
          <form
            onSubmit={handleFormSubmit}
            className="flex w-full flex-col gap-2"
          >
            <div className="w-full">
              <p className="font-semibold text-gray-800 text-lg mb-2">Logo</p>
              <LogoInput
                handleLogoChange={(file) => {
                  return setLogo(file);
                }}
                removeImage={() => {
                  return setLogo(null);
                }}
                prefetchedLogo={logo}
              />
            </div>

            <div className="w-full">
              <p className="font-semibold text-gray-800 text-lg mb-2">
                Banner image
              </p>
              <BannerInput
                handleBannerChange={(file) => {
                  return setBanner(file);
                }}
                removeImage={() => {
                  return setBanner(null);
                }}
                prefetchedBanner={banner}
              />
            </div>

            <label
              htmlFor="title"
              className="font-semibold text-gray-800 text-lg"
            >
              Title
              <input
                id="title"
                type="text"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  return setTitle(e.target.value);
                }}
                value={title || ''}
                required
              />
            </label>

            <label
              htmlFor="description"
              className="font-semibold text-gray-800 text-lg"
            >
              Description
              <textarea
                id="description"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                cols="20"
                rows="2"
                onChange={(e) => {
                  return setDescription(e.target.value);
                }}
                value={description || ''}
                required
              />
            </label>

            <label
              htmlFor="offerDetails"
              className="font-semibold text-gray-800 text-lg"
            >
              Offer details
              <textarea
                id="offerDetails"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                cols="20"
                rows="2"
                onChange={(e) => {
                  return setOfferDetails(e.target.value);
                }}
                value={offerDetails || ''}
                required
              />
            </label>

            <label
              htmlFor="howToRedeem"
              className="font-semibold text-gray-800 text-lg"
            >
              How to redeem
              <textarea
                id="howToRedeem"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                cols="20"
                rows="2"
                onChange={(e) => {
                  return setHowToRedeem(e.target.value);
                }}
                value={howToRedeem || ''}
                required
              />
            </label>

            <label
              htmlFor="link"
              className="font-semibold text-gray-800 text-lg"
            >
              Redemption link
              <input
                id="link"
                type="url"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  return setLink(e.target.value);
                }}
                value={link || ''}
                required
              />
            </label>

            <label
              htmlFor="terms"
              className="font-semibold text-gray-800 text-lg"
            >
              Terms
              <textarea
                id="terms"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                cols="20"
                rows="2"
                onChange={(e) => {
                  return setTerms(e.target.value);
                }}
                value={terms || ''}
                required
              />
            </label>

            <label
              htmlFor="time"
              className="font-semibold text-gray-800 text-lg mt-2"
            >
              Expiry Date
              <input
                value={expiryDate}
                id="time"
                className="mt-2 w-full bg-white border border-gray-300 focus:border-blue-600 focus:outline-none text-gray-800 text-base leading-6 rounded p-2.5 appearance-none cursor-pointer"
                type="datetime-local"
                name="time"
                onChange={(e) => {
                  return updateTime(e.target.value);
                }}
                required
              />
            </label>
            <label
              htmlFor="site"
              className="font-semibold text-gray-800 text-lg"
            >
              Enabled on sites
              <select
                size="20"
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.currentTarget.focus();
                  const el = e.target;
                  const { scrollTop } = el.parentNode;
                  setTimeout(() => {
                    return el.parentNode.scrollTo(0, scrollTop);
                  }, 1);
                  setOfferSite(e.target.value);
                }}
                onChange={(e) => {
                  e.preventDefault();
                }}
                multiple
                value={selectedSites}
                id="site"
                className="
                    mt-2
                    mb-2
                    w-full
                    bg-white
                    border
                    border-gray-300
                    focus:border-blue-600
                    focus:outline-none
                    text-gray-800
                    text-base
                    leading-6
                    rounded
                    p-2.5
                    appearance-none
                    cursor-pointer
                  "
              >
                <option value="">All Sites</option>
                {allSites?.map((site) => {
                  return (
                    <option key={site?.siteCode} value={site?.siteCode}>
                      {site?.topLocation?.name} - ({site?.siteCode})
                    </option>
                  );
                })}
              </select>
            </label>

            <div className="w-full flex items-center justify-end">
              <NewsfeedButton
                classNames="md:w-auto w-full"
                loading={posting}
                title="Submit"
                type="primary"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OfferAdminModal;

OfferAdminModal.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  posting: PropTypes.bool.isRequired,
  postOffer: PropTypes.func,
  preFetchedOffer: PropTypes.string,
  type: PropTypes.oneOf(['add', 'edit']),
  sites: PropTypes.string,
  bgClass: PropTypes.string
};

OfferAdminModal.defaultProps = {
  avatar: null,
  postOffer: () => {},
  preFetchedOffer: null,
  type: 'add',
  sites: null,
  bgClass: '0'
};
