/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import { useTranslation } from 'react-i18next';
import { renderBusinessAvatar, renderUserAvatar } from '../../Util/data';
import Loader from '../Loader/Loader';
import AttachmentRevamp from '../AttachmentRevamp/AttachmentRevamp';

const MessageBox = ({
  sentByCurrentUser,
  message,
  date,
  conversationUserName,
  logoUrl,
  attachments = [],
  isPending,
  connection,
  locale,
  acceptConnection,
  rejectConnection,
  isProposalUser,
  lastMessage,
  acceptingNudge,
  rejectingNudge,
  bgClass
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-undef
  const currentLang = document.documentElement.lang;

  return (
    <div className="w-full mb-5">
      <div
        className={`
        flex
        flex-col
        ${sentByCurrentUser ? 'items-end' : 'items-start'}
      `}
      >
        <div className="md:w-auto md:max-w-sm xl:max-w-md w-full">
          <div
            className={`flex items-end gap-4 ${
              sentByCurrentUser ? 'flex-row-reverse' : ''
            }`}
          >
            <div className="min-w-max md:block hidden">
              {logoUrl ? (
                <img
                  src={logoUrl}
                  alt={conversationUserName.split(' ')[0]}
                  className="w-12 h-12 rounded-full object-center object-cover"
                />
              ) : conversationUserName === 'System null' ? (
                <img
                  src="/images/small/logo/ain_new-logo.png"
                  className="w-12 h-12 rounded-full"
                  alt="AIN"
                />
              ) : (
                renderUserAvatar(conversationUserName, null, `bg-${bgClass}`)
              )}
            </div>
            {isPending && !isProposalUser ? (
              <div className="flex flex-col">
                <span className="block mb-3 text-xs">
                  {moment(date).locale(currentLang).fromNow()}
                </span>
                <div
                  className="
                    relative
                    rounded-2xl
                    px-5
                    py-3
                    md:text-base
                    text-sm
                    bg-gray-200
                    text-gray-700
                    break-words
                    message-box
                  "
                >
                  {lastMessage?.content.length ? (
                    <Markdown>{lastMessage.content}</Markdown>
                  ) : (
                    <p>{t('auth.inbox.salutation')}</p>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 17 21"
                    className="
                      w-5
                      h-5
                      absolute
                      bottom-0
                      fill-current
                      text-gray-200
                      -left-1.5
                      transform
                      scale-x-negative
                    "
                  >
                    <path d="M16.8869 20.1846C11.6869 20.9846 6.55352 18.1212 4.88685 16.2879C6.60472 12.1914 -4.00107 2.24186 2.99893 2.24148C4.61754 2.24148 6 -1.9986 11.8869 1.1846C11.9081 2.47144 11.8869 6.92582 11.8869 7.6842C11.8869 18.1842 17.8869 19.5813 16.8869 20.1846Z" />
                  </svg>
                </div>
              </div>
            ) : (
              <div className="flex flex-col">
                <span
                  className={`block mb-3 text-xs ${
                    sentByCurrentUser ? 'text-right' : ''
                  }`}
                >
                  {moment(date).locale(currentLang).fromNow()}
                </span>
                <div
                  className={`
                    relative
                    rounded-2xl
                    px-5
                    py-3
                    md:text-base
                    text-sm
                    ${
                      sentByCurrentUser
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 text-gray-700'
                    }
                    break-words
                    message-box
                  `}
                >
                  <Markdown
                    options={{
                      overrides: {
                        a: {
                          props: {
                            target: '_blank'
                          }
                        }
                      }
                    }}
                  >
                    {/* this replace prevent to overload markdown regex expression because of too much spaces in message */}
                    {(message = message.replace(/ {21,}/g, ' '.repeat(20)))}
                  </Markdown>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 17 21"
                    className={`
                      w-5
                      h-5
                      absolute
                      bottom-0
                      ${
                        sentByCurrentUser
                          ? 'fill-current text-blue-600 right-message-box-right'
                          : 'fill-current text-gray-200 left-message-box-left transform scale-x-negative'
                      }
                    `}
                  >
                    <path d="M16.8869 20.1846C11.6869 20.9846 6.55352 18.1212 4.88685 16.2879C6.60472 12.1914 -4.00107 2.24186 2.99893 2.24148C4.61754 2.24148 6 -1.9986 11.8869 1.1846C11.9081 2.47144 11.8869 6.92582 11.8869 7.6842C11.8869 18.1842 17.8869 19.5813 16.8869 20.1846Z" />
                  </svg>
                </div>
              </div>
            )}
          </div>
          {isPending && !isProposalUser && (
            <div className="md:max-w-sm w-full mt-3 md:ml-4 relative md:left-12">
              <div className="rounded-lg p-4 border border-gray-200">
                <div className="flex flex-wrap items-center gap-2 mb-4">
                  <div className="w-20 h-20 bg-gray-200 rounded-md overflow-hidden">
                    {connection?.proposal?.logoUrl ? (
                      <img
                        src={connection.proposal.logoUrl}
                        alt={connection.proposal.title}
                        className="w-full h-full object-center"
                      />
                    ) : (
                      renderBusinessAvatar(
                        connection.proposal.title,
                        'w-20 h-20',
                        `bg-${bgClass}`
                      )
                    )}
                  </div>
                  <div className="flex-grow">
                    <h4 className="text-gray-800 font-semibold md:text-xl text-base truncate mb-0">
                      {connection.proposal.title}
                    </h4>
                    <p className="flex items-center text-gray-700 text-sm">
                      <span className="material-icons text-base text-blue-400">
                        location_on
                      </span>
                      {connection?.proposal?.locationName
                        ? `${connection.proposal.locationName}, `
                        : ''}{' '}
                      {connection.proposal.countryName}
                    </p>
                  </div>
                </div>
                <p className="text-gray-700 text-base leading-6">
                  {connection?.proposal?.description}
                </p>
                <div className="flex flex-row mt-4">
                  <div className="w-1/2">
                    <h4 className="mb-1 text-xl font-semibold text-gray-800 leading-8">
                      {t('currency', {
                        val: connection.proposal.maxInvestment,
                        formatParams: {
                          val: {
                            currency: connection.proposal.currencyCode,
                            locale: locale?.replace(/_/g, '-'),
                            maximumFractionDigits: 0
                          }
                        }
                      })}
                    </h4>
                    <span className="text-gray-700 text-sm">
                      {t('proposalsMacro.totalRequired')}
                    </span>
                  </div>
                  <div className="w-1/2">
                    <h4 className="mb-1 text-xl font-semibold text-gray-800 leading-8">
                      {t('currency', {
                        val: connection.proposal.minInvestment,
                        formatParams: {
                          val: {
                            currency: connection.proposal.currencyCode,
                            locale: locale?.replace(/_/g, '-'),
                            maximumFractionDigits: 0
                          }
                        }
                      })}
                    </h4>
                    <span className="text-gray-700 text-sm">
                      {t('proposalsMacro.minPerInvestor')}
                    </span>
                  </div>
                </div>
                <div className="my-5 w-full">
                  <div className="flex flex-wrap gap-2 mt-3 pb-4">
                    <button
                      type="button"
                      onClick={() => {
                        return acceptConnection(
                          connection.connectionUserActions.acceptConnectionUri
                        );
                      }}
                      className="text-sm py-2 px-3 bg-blue-600 hover:bg-blue-700 text-white rounded-md font-semibold"
                    >
                      {acceptingNudge ? (
                        <Loader title="Loading" />
                      ) : (
                        t('auth.inbox.interested')
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        return rejectConnection(
                          connection.connectionUserActions.rejectConnectionUri
                        );
                      }}
                      className="text-sm py-2 px-3 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-md font-semibold"
                    >
                      {rejectingNudge ? (
                        <Loader title="Loading" />
                      ) : (
                        t('auth.inbox.ignore')
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-3">
                {t('auth.inbox.clickInterested').replace(
                  '%name%',
                  conversationUserName
                )}
              </p>
            </div>
          )}
          {attachments?.length > 0 &&
            attachments?.map((attachment, index) => {
              return (
                <AttachmentRevamp
                  key={index}
                  attachment={attachment}
                  classNames={`${
                    sentByCurrentUser ? 'md:right-12' : 'md:left-12'
                  }`}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MessageBox;

MessageBox.propTypes = {
  sentByCurrentUser: PropTypes.bool.isRequired,
  message: PropTypes.string,
  date: PropTypes.string,
  conversationUserName: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  attachments: PropTypes.instanceOf(Object),
  isPending: PropTypes.bool,
  connection: PropTypes.instanceOf(Object),
  locale: PropTypes.string,
  acceptConnection: PropTypes.func,
  rejectConnection: PropTypes.func,
  isProposalUser: PropTypes.bool,
  lastMessage: PropTypes.instanceOf(Object),
  acceptingNudge: PropTypes.bool,
  rejectingNudge: PropTypes.bool,
  bgClass: PropTypes.string
};

MessageBox.defaultProps = {
  logoUrl: null,
  attachments: null,
  isPending: false,
  connection: null,
  locale: null,
  acceptConnection: () => {},
  rejectConnection: () => {},
  isProposalUser: false,
  lastMessage: null,
  acceptingNudge: false,
  rejectingNudge: false,
  message: null,
  date: null,
  bgClass: '0'
};
