/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import {
  formatInvestorName,
  getLastCharacter,
  renderUserAvatar,
  roundUpInvestmentFigure
} from '../../Util/data';
import { shortlistInvestor, unShortlistInvestor } from '../../Util/ApiService';
import Loader from '../Loader/Loader';
import ShortlistButton from '../Buttons/Shortlist';
import Tooltip from '../Tooltip/Tooltip';

const InvestorCard = ({
  investor,
  locale,
  nudgeUri,
  shortlistUri,
  openNudgeModal,
  isLoggedIn,
  alreadyNudged,
  currencyCode
}) => {
  const { t } = useTranslation();
  const [shortlistLoading, setShortlistLoading] = useState(false);
  const [nudging] = useState(false);
  const [isShortlisted, setIsShortlisted] = useState(
    investor?.investorUserActions?.alreadyShortlisted
  );
  const [selectedNudgeUri, setSelectedNudgeUri] = useState(null);
  const [selectedUserNudgeUri, setSelectedUserNudgeUri] = useState(null);
  const [isAlreadyNudged, setAlreadyNudged] = useState(
    investor?.investorUserActions?.alreadyNudged
  );
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const shortList = async (url) => {
    setShortlistLoading(true);
    if (isShortlisted) {
      await unShortlistInvestor(url);
    } else {
      await shortlistInvestor(url);
    }
    setIsShortlisted(!isShortlisted);
    setShortlistLoading(false);
  };

  const nudge = () => {
    openNudgeModal(nudgeUri, t('nudgeModal.sendMessage'), 'nudge');
    setSelectedNudgeUri(nudgeUri);
  };

  const goToInbox = () => {
    // eslint-disable-next-line no-undef
    window.open(investor?.investorUserActions?.inboxConnectionUri, '_blank');
  };

  useEffect(() => {
    if (alreadyNudged && selectedUserNudgeUri === selectedNudgeUri) {
      setAlreadyNudged(true);
    }
    setSelectedUserNudgeUri(nudgeUri);
  }, [alreadyNudged]);

  return (
    <div className="w-full md:max-w-md md:min-w-sm mx-auto flex flex-col py-6 px-4">
      <div className="bg-white rounded-lg h-full flex flex-col gap-4 shadow-3xl p-6">
        <div className="w-full flex items-center gap-4 relative">
          {investor.logoUrl ? (
            <img
              className="w-20 h-20 rounded-full object-center object-cover flex-shrink-0"
              src={investor.logoUrl}
              alt={investor.fullName}
            />
          ) : isClient ? (
            renderUserAvatar(
              investor.fullName,
              'w-20 h-20 flex-shrink-0',
              `bg-${getLastCharacter(investor.investorId)}`
            )
          ) : null}
          <div className="flex flex-col">
            <h3 className="text-gray-800 text-xl font-semibold leading-8">
              {formatInvestorName(investor?.fullName)}
            </h3>
            <p className="text-sm text-gray-700 font-normal flex items-center gap-1">
              <span className="material-icons text-gray-500 -mt-0.5">
                location_on
              </span>
              {investor.city ? `${investor.city}, ` : ''}
              {investor.countryName}
            </p>
          </div>
          {shortlistUri && (
            <div className="absolute top-0 right-0">
              <ShortlistButton
                onClick={() => {
                  return shortList(shortlistUri);
                }}
                loading={shortlistLoading}
                isShortlisted={isShortlisted}
                isInvestorsPage
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <h4 className="text-gray-800 text-base font-semibold leading-6">
              {t('findInvestors.investmentRange')}
            </h4>
            <p className="text-gray-800 leading-8 text-xl font-semibold">
              {t('currency', {
                val: roundUpInvestmentFigure(
                  investor.minInvestment * investor.exchangeRateToSiteCurrency
                ),
                formatParams: {
                  val: {
                    currency: currencyCode,
                    locale: locale.replace(/_/g, '-'),
                    maximumFractionDigits: 0
                  }
                }
              })}
              &nbsp;-&nbsp;
              {t('currency', {
                val: roundUpInvestmentFigure(
                  investor.maxInvestment * investor.exchangeRateToSiteCurrency
                ),
                formatParams: {
                  val: {
                    currency: currencyCode,
                    locale: locale.replace(/_/g, '-'),
                    maximumFractionDigits: 0
                  }
                }
              })}
            </p>
          </div>
          <p className="line-clamp-3 text-sm text-gray-700 leading-6 flex-1">
            {investor.background?.length > 250
              ? `${investor.background.slice(0, 250)} ...`
              : investor.background}
          </p>
          <div className="flex flex-col gap-1">
            <h5 className="text-base leading-6 font-semibold text-gray-800">
              {t('investorCard.areasOfExpertise')}
            </h5>
            <p className="line-clamp-3 text-sm text-gray-700 leading-6 flex-1">
              {investor.expertise?.length > 250
                ? `${investor.expertise.slice(0, 250)} ...`
                : investor.expertise}
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-2 mt-auto">
          {isLoggedIn && (
            <Tooltip
              text={
                investor?.investorUserActions?.inboxConnectionUri
                  ? t('investorCard.viewThread')
                  : !isAlreadyNudged
                  ? t('investorCard.nudgeInvestor')
                  : null
              }
            >
              <button
                type="button"
                className={`
                  bg-blue-600
                  hover:bg-blue-500
                  text-white
                  rounded-md
                  py-1
                  px-3
                  font-semibold
                  text-sm
                  leading-6
                  whitespace-nowrap
                `}
                onClick={() => {
                  return isAlreadyNudged ? goToInbox() : nudge();
                }}
                disabled={
                  isAlreadyNudged &&
                  !investor?.investorUserActions?.inboxConnectionUri
                }
              >
                {nudging ? (
                  <Loader title="Loading" />
                ) : investor?.investorUserActions?.inboxConnectionUri ? (
                  t('investorCard.message')
                ) : isAlreadyNudged ? (
                  t('investorCard.alreadyNudged')
                ) : (
                  t('investorCard.nudge')
                )}
              </button>
            </Tooltip>
          )}
          <a
            href={investor.detailsUrl}
            target="_blank"
            className={`
              ${
                !isLoggedIn
                  ? 'bg-blue-600 hover:bg-blue-500 text-white'
                  : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
              }
              rounded-md
              py-1
              px-3
              font-semibold
              text-sm
              leading-6
              whitespace-nowrap
            `}
            rel="noreferrer"
          >
            {t('investorCard.moreDetails')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default InvestorCard;

InvestorCard.propTypes = {
  investor: PropTypes.instanceOf(Object),
  locale: PropTypes.string,
  nudgeUri: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  shortlistUri: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  openNudgeModal: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  alreadyNudged: PropTypes.bool,
  currencyCode: PropTypes.string
};

InvestorCard.defaultProps = {
  investor: null,
  locale: '',
  nudgeUri: '',
  shortlistUri: '',
  openNudgeModal: () => {},
  isLoggedIn: false,
  alreadyNudged: false,
  currencyCode: ''
};
