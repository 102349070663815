/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import ProposalSummary from '../../Components/ProposalSummary/ProposalSummary';
import LoadMore from '../../Components/Buttons/LoadMore';
import ListButton from '../../Components/Buttons/ListButton';
import {
  fetchProposals,
  fetchIndustries,
  fetchStages,
  fetchLanguages,
  fetchFundingTypes,
  fetchTaxReliefs,
  fetchPlaces
} from '../../Util/ApiService';
import Dropdown from '../../Components/Dropdown/Dropdown';
import Pagination from '../../Components/Pagination/Pagination';
import TagsInput from '../../Components/TagsInput/TagsInput';
import Select from '../../Components/Select/Select';
import Skeleton from '../../Components/Card/Skeleton';
import { removeEntryFromArray, defaultUKLocationId } from '../../Util/data';

const BusinessProposalPage = ({
  selectedIndustryId,
  lastPageNumber,
  preFetchedReponse,
  isLoggedIn,
  locale,
  apiUrl,
  industriesUrl,
  stagesUrl,
  languagesUrl,
  fundingTypesUrl,
  taxReliefUrl,
  locationsUrl,
  searchBarQuery,
  currencyCode,
  maxInvestmentLimit,
  topLocationId,
  defaultPageSize,
  displayLoadMoreButton,
  siteCode,
  preSetPageNumber,
  pageHeader,
  pageDescription,
  selectedLocationId,
  selectedLocationType,
  investmentRange,
  businessProposalUrl
}) => {
  // convert preFetchedresponse string to JSON
  const preRenderedData = JSON.parse(preFetchedReponse);

  const [preEnteredTag, setPreEnteredTag] = useState(searchBarQuery);
  const [preSelectedIndustryId] = useState(selectedIndustryId);
  const [preSelectedLocationId] = useState(selectedLocationId);
  const [preSelectedLocationType] = useState(selectedLocationType);
  const showTaxRelief = siteCode === 'AIN_UK';

  const skeletonCards = Array(9).fill(<Skeleton />);
  const [preEnteredPageNumber, setPreEnteredPageNumber] =
    useState(preSetPageNumber);
  const [pageNumber, setPageNumber] = useState(preSetPageNumber);
  const [updatedPageNumber, setUpdatedPageNumber] = useState(1);
  const [totalResults, setTotalResults] = useState(
    preRenderedData.pagination.totalResults
  );
  const [proposals, setProposals] = useState(preRenderedData?.results);
  const [industries, setIndustries] = useState([]);
  const [stages, setStages] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [fundingTypes, setFundingTypes] = useState([]);
  const [taxReliefs, setTaxReliefs] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(
    displayLoadMoreButton
  );
  const [showNoResultsLabel, setShowNoResultsLabel] = useState(false);
  const [showIndustries, setShowIndustries] = useState(false);
  const [showStages, setShowStages] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  // const [showQuickLinks] = useState(false);
  const [showContinents, setShowContinents] = useState(false);
  const [showTaxReliefs, setShowTaxReliefs] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [showFundingTypes, setShowFundingTypes] = useState(false);
  const [currentContinentId] = useState(0);
  const [countries, setCountries] = useState([]);
  const [continents, setContinents] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isTagsEmpty, setEmptyTags] = useState(false);
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [showRange, setShowRange] = useState(false);
  const [isRangeDefault, setRangeDefault] = useState(true);

  const [checkedIndustries, setCheckedIndustries] = useState([]);
  const [checkedQuickLinks, setCheckedQuickLinks] = useState([]);
  const [checkedStages, setCheckedStages] = useState([]);
  const [checkedLanguages, setCheckedLanguages] = useState([]);
  const [checkedTaxreliefs, setCheckedTaxReliefs] = useState([]);
  const [checkedFundingTypes, setCheckedFundingTypes] = useState([]);
  const [checkedContinents, setCheckedContinents] = useState([]);
  const [checkedCountries, setCheckedCountries] = useState([]);
  const [enteredTags, setEnteredTags] = useState([]);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [minInvestment, setMinInvestment] = useState(null);
  const [maxInvestment, setMaxInvestment] = useState(null);
  const [additionalInput, setAdditionalInput] = useState(null);
  const [preselectedContinentId, setPreSelectedContinentId] = useState(null);

  const removeProposal = (index) => {
    const proposalsArray = [...proposals];
    const newArray = removeEntryFromArray(proposalsArray, index);
    setProposals([...newArray]);
  };

  const { t } = useTranslation();

  const getProposals = async (newPageNumber) => {
    setUpdatedPageNumber(newPageNumber);
    // fixme we're already setting the preRenderedData on clearFilters so we can remove these checks
    if (
      preEnteredPageNumber &&
      preEnteredPageNumber !== 1 &&
      !checkedContinents.length &&
      !checkedIndustries.length &&
      !checkedFundingTypes.length &&
      !checkedStages.length &&
      !checkedLanguages.length &&
      !checkedTaxreliefs.length &&
      !checkedCountries.length &&
      !enteredTags.length &&
      !checkedLocations.length &&
      !selectedOption &&
      !minInvestment &&
      !maxInvestment &&
      !preEnteredTag &&
      !preSelectedIndustryId &&
      !preSelectedLocationId &&
      !preSelectedLocationType
    ) {
      setProposals(preRenderedData.results);
      const totalProposals = preRenderedData.pagination.totalResults;
      setTotalResults(preRenderedData.pagination.totalResults);
      setPageNumber(preSetPageNumber);
      setShowLoadMoreButton(
        totalProposals > preSetPageNumber * defaultPageSize
      );
      setLoading(false);
      setSearching(false);
      return;
    }
    if (
      !isLoggedIn &&
      preRenderedData &&
      preRenderedData.pagination.totalResults &&
      !checkedContinents.length &&
      !checkedIndustries.length &&
      !checkedFundingTypes.length &&
      !checkedStages.length &&
      !checkedLanguages.length &&
      !checkedTaxreliefs.length &&
      !checkedCountries.length &&
      !enteredTags.length &&
      !checkedLocations.length &&
      !selectedOption &&
      !minInvestment &&
      !maxInvestment &&
      pageNumber === newPageNumber &&
      newPageNumber === 1 &&
      !preEnteredTag &&
      preEnteredPageNumber &&
      preEnteredPageNumber === 1 &&
      !preSelectedIndustryId &&
      !preSelectedLocationId &&
      !preSelectedLocationType
    ) {
      setProposals(preRenderedData.results);
      const totalProposals = preRenderedData.pagination.totalResults;
      setTotalResults(totalProposals);
      setPageNumber(newPageNumber);
      setShowLoadMoreButton(totalProposals > newPageNumber * defaultPageSize);
      setLoading(false);
      setSearching(false);
      return;
    }
    setLoading(true);
    const newProposals = await fetchProposals(apiUrl, {
      pageNumber: newPageNumber,
      industries: checkedIndustries || null,
      stages: checkedStages,
      languages: checkedLanguages,
      taxReliefs: checkedTaxreliefs,
      fundingTypes: checkedFundingTypes,
      searchQuery: enteredTags || null,
      locations: checkedCountries.concat(checkedLocations),
      orderByOption: selectedOption || null,
      minInvestment: minInvestment || null,
      maxInvestment: maxInvestment || null
    });
    const totalProposals = Number(newProposals.pagination.totalResults) || 0;
    setShowNoResultsLabel(!totalProposals);
    setTotalResults(totalProposals);
    // eslint-disable-next-line no-unused-expressions
    newPageNumber === 1
      ? setProposals(newProposals.results)
      : !isLoggedIn
      ? setProposals(newProposals.results)
      : setProposals([...proposals, ...newProposals.results]);
    setPageNumber(newPageNumber);
    setShowLoadMoreButton(totalProposals > newPageNumber * defaultPageSize);
    setLoading(false);
    setSearching(false);
  };

  // get industries
  const getIndustries = async () => {
    const result = await fetchIndustries(industriesUrl);
    setIndustries(result);
    // pre select industry if the route contains industry id
    if (preSelectedIndustryId && result) {
      setCheckedIndustries([
        ...checkedIndustries,
        selectedIndustryId.toString()
      ]);
      // eslint-disable-next-line no-unused-expressions
      preEnteredPageNumber
        ? setPageNumber(preEnteredPageNumber)
        : setPageNumber(pageNumber);
    }
  };

  useEffect(() => {
    if (checkedIndustries?.length) {
      getProposals(pageNumber);
    }
  }, [checkedIndustries]);

  // get stages
  const getStages = async () => {
    const result = await fetchStages(stagesUrl);
    setStages(result);
  };

  // get continent
  const getContinents = async () => {
    // eslint-disable-next-line no-shadow
    const continents = await fetchPlaces(locationsUrl, {
      topLocations: true
    });
    if (continents?.results) setContinents(continents.results);
  };

  // get locations inside a country
  const getLocationsByCountry = async (id) => {
    if (topLocationId === parseInt(id, 10)) {
      const regions = await fetchPlaces(locationsUrl, {
        parentLocation: id || defaultUKLocationId
      });
      if (regions?.results) setLocations(regions.results);
    } else {
      setLocations([]);
    }
  };

  // get available languages
  const getLanguages = async () => {
    const result = await fetchLanguages(languagesUrl, {
      siteLanguageOnly: true
    });
    setLanguages(result);
  };

  // get funding types
  const getFundingTypes = async () => {
    const result = await fetchFundingTypes(fundingTypesUrl);
    setFundingTypes(result);
  };

  // get tax reliefs
  const getTaxReliefs = async () => {
    const result = await fetchTaxReliefs(taxReliefUrl);
    setTaxReliefs(result);
  };

  const clearRange = () => {
    setMaxInvestment(maxInvestmentLimit);
    setMinInvestment(1);
    setRangeDefault(true);
    getProposals(1);
  };

  const handleRange = (values) => {
    setRangeDefault(false);
    setMinInvestment(values[0]);
    setMaxInvestment(values[1]);
  };

  const clearFilters = () => {
    setPageNumber(1);
    setUpdatedPageNumber(1);
    setCheckedIndustries([]);
    setCheckedFundingTypes([]);
    setCheckedQuickLinks([]);
    setCheckedLanguages([]);
    setCheckedTaxReliefs([]);
    setCheckedStages([]);
    setCheckedContinents([]);
    setCheckedCountries([]);
    setEnteredTags([]);
    setCheckedLocations([]);
    setSelectedOption(null);
    setEmptyTags(true);
    setPreEnteredTag(null);
    setMinInvestment(1);
    setMaxInvestment(maxInvestmentLimit);
    setTimeout(() => {
      setEmptyTags(false);
    }, 2000);
    setTotalResults(preRenderedData.pagination.totalResults);
    setProposals(preRenderedData?.results);
    setShowLoadMoreButton(displayLoadMoreButton);
    setShowNoResultsLabel(false);
  };

  const clearSelection = (childArray, category) => {
    if (childArray) {
      setCheckedContinents([]);
      setCheckedCountries([]);
      return getProposals(1);
    }

    // eslint-disable-next-line default-case
    switch (category) {
      case 'quickLinks':
        setCheckedQuickLinks([]);
        break;
      case 'industries':
        setCheckedIndustries([]);
        break;
      case 'stages':
        setCheckedStages([]);
        break;
      case 'languages':
        setCheckedLanguages([]);
        break;
      case 'taxRelief':
        setCheckedTaxReliefs([]);
        break;
      case 'funding':
        setCheckedFundingTypes([]);
        break;
      case 'locations':
        setCheckedLocations([]);
        break;
    }
    return getProposals(1);
  };

  // eslint-disable-next-line consistent-return
  const updateCheckbox = (e, array, callbackFunc, isChecked, countriesList) => {
    if (!isChecked) {
      if (array === checkedContinents) {
        // eslint-disable-next-line array-callback-return
        countriesList.some((countryObject) => {
          const isFound = checkedCountries.some((country) => {
            return countryObject.locationId.toString().includes(country);
          });
          if (!isFound) {
            return;
          }
          const newArray = removeEntryFromArray(
            checkedCountries,
            checkedCountries.indexOf(countryObject.locationId.toString())
          );
          setCheckedCountries([...newArray]);
        });
      }
      const checkedArray = [...array];
      const indexOfElemToBeDeleted = array.indexOf(e.target.value);
      const newArray = removeEntryFromArray(
        checkedArray,
        indexOfElemToBeDeleted
      );
      return callbackFunc([...newArray]);
    }
    callbackFunc([...array, e.target.value]);
    if (array === checkedContinents) {
      // solution from https://stackoverflow.com/a/9229821
      setCheckedCountries([
        ...new Set([
          ...countriesList.map((country) => {
            return country.locationId.toString();
          }),
          ...checkedCountries
        ])
      ]);
    }
  };

  const handleContinentsChange = (array) => {
    setCheckedContinents(array);
  };

  const handleChange = (e, category, name, index, countriesList) => {
    const isChecked = e.target.checked;
    // eslint-disable-next-line default-case
    switch (category) {
      case 'quickLinks':
        updateCheckbox(e, checkedQuickLinks, setCheckedQuickLinks, isChecked);
        break;
      case 'industries':
        updateCheckbox(e, checkedIndustries, setCheckedIndustries, isChecked);
        break;
      case 'stages':
        updateCheckbox(e, checkedStages, setCheckedStages, isChecked);
        break;
      case 'languages':
        updateCheckbox(e, checkedLanguages, setCheckedLanguages, isChecked);
        break;
      case 'taxRelief':
        updateCheckbox(e, checkedTaxreliefs, setCheckedTaxReliefs, isChecked);
        break;
      case 'funding':
        updateCheckbox(
          e,
          checkedFundingTypes,
          setCheckedFundingTypes,
          isChecked
        );
        break;
      case 'continents':
        updateCheckbox(
          e,
          checkedContinents,
          handleContinentsChange,
          isChecked,
          countriesList
        );
        break;
      case 'locations':
        updateCheckbox(e, checkedLocations, setCheckedLocations, isChecked);
        break;
    }
  };

  const handleCountryChange = (e) => {
    updateCheckbox(e, checkedCountries, setCheckedCountries, e.target.checked);
    if (e.target.checked) {
      getLocationsByCountry(e.target.value);
    }
  };

  const updateCountries = (e) => {
    setCountries([...countries, ...e]);
  };

  const updateCountriesList = (e) => {
    setAllCountriesList([...allCountriesList, ...e]);
  };

  // manipulating pre selected country
  useEffect(() => {
    if (
      countries &&
      preSelectedLocationId &&
      preSelectedLocationType === 'CN'
    ) {
      const countryFound = countries?.find((country) => {
        return (
          country?.locationId.toString() === selectedLocationId?.toString()
        );
      });
      if (countryFound) {
        setCheckedCountries([
          ...checkedCountries,
          selectedLocationId?.toString()
        ]);
        // eslint-disable-next-line no-unused-expressions
        preEnteredPageNumber
          ? setPageNumber(preEnteredPageNumber)
          : setPageNumber(pageNumber);
        getLocationsByCountry(selectedLocationId);
        setPreSelectedContinentId(countryFound?.parentLocation);
      }
    }
  }, [countries]);

  useEffect(() => {
    if (checkedCountries?.length) {
      getProposals(pageNumber);
    }
  }, [checkedCountries]);

  // manipulating pre selected location
  useEffect(() => {
    if (
      locations &&
      preSelectedLocationId &&
      preSelectedLocationType === 'RG'
    ) {
      const locationFound = locations?.find((location) => {
        return (
          location?.locationId.toString() === selectedLocationId?.toString()
        );
      });
      if (locationFound) {
        setCheckedLocations([
          ...checkedLocations,
          selectedLocationId?.toString()
        ]);
        // eslint-disable-next-line no-unused-expressions
        preEnteredPageNumber
          ? setPageNumber(preEnteredPageNumber)
          : setPageNumber(pageNumber);
      }
    }
  }, [locations]);

  useEffect(() => {
    if (checkedLocations?.length) {
      getProposals(pageNumber);
    }
  }, [checkedLocations]);

  const applyFilters = async () => {
    setSearching(true);
    setPageNumber(1);
    await getProposals(1);
    setSearching(false);
  };

  const searchWithQuery = (query) => {
    if (query?.length) {
      return setEnteredTags(query);
    }
    return setEnteredTags([]);
  };

  const storeAdditionalInput = (input) => {
    if (!input.length || !input) {
      setAdditionalInput(null);
      const newTagsArray = enteredTags.pop();
      setEnteredTags([...newTagsArray]);
    }

    if (!enteredTags.length && !input.length) {
      setEnteredTags([]);
      setAdditionalInput(null);
    } else {
      setAdditionalInput(input);
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e);
  };

  // get proposals on orderByOption change
  useEffect(() => {
    if (selectedOption) {
      getProposals(1);
    }
  }, [selectedOption]);

  const searchWithTags = async () => {
    if (additionalInput) {
      setEnteredTags([...new Set([...enteredTags, additionalInput])]);
    }
  };

  const loadMoreProposals = () => {
    if (
      preEnteredPageNumber &&
      !preSelectedIndustryId &&
      !preSelectedLocationId &&
      !preSelectedLocationType
    ) {
      setPreEnteredPageNumber(null);
      setPageNumber(pageNumber + 1);
      getProposals(pageNumber + 1);
    } else if (
      preEnteredPageNumber &&
      preSelectedIndustryId &&
      preSelectedLocationId &&
      preSelectedLocationType
    ) {
      setPreEnteredPageNumber(null);
      getProposals(pageNumber + 1);
    } else {
      getProposals(pageNumber + 1);
    }
  };

  useEffect(() => {
    if (preEnteredTag) {
      setEnteredTags([...enteredTags, preEnteredTag]);
    }
  }, [preEnteredTag]);

  useEffect(() => {
    if (enteredTags?.length) {
      setSearching(true);
      getProposals(1);
    }
  }, [enteredTags]);

  const unsetPreRenderedTag = () => {
    setPreEnteredTag(null);
  };

  useEffect(() => {
    getIndustries();
    getContinents();
    getStages();
    getLanguages();
    getFundingTypes();
    if (showTaxRelief) {
      getTaxReliefs();
    }
    getLocationsByCountry(topLocationId);
  }, []);

  return (
    <div className="w-full flex flex-col items-center mt-8 min-h-screen">
      <div className="w-full lg:max-w-screen-xl">
        <div className="w-full px-4 overflow-x-clip">
          <Dropdown
            title={t('findInvestors.investmentRange')}
            showDropdown={showRange}
            category="multiRange"
            selectedArray={[minInvestment, maxInvestment]}
            locale={locale}
            onShowDropdown={() => {
              return setShowRange(true);
            }}
            onHideDropdown={() => {
              return setShowRange(false);
            }}
            applyFilters={() => {
              return applyFilters();
            }}
            setValues={handleRange}
            hasChildren={false}
            clearFilters={() => {
              return clearRange();
            }}
            currencyCode={currencyCode}
            maxInvestmentLimit={maxInvestmentLimit}
            isDefaultRange={isRangeDefault}
            investmentRange={investmentRange}
          />

          <Dropdown
            title={t('businessProposal.country')}
            showDropdown={showContinents}
            category="continents"
            selectedArray={checkedContinents}
            selectedChildrenArray={checkedCountries}
            array={continents}
            onShowDropdown={() => {
              return setShowContinents(true);
            }}
            onHideDropdown={() => {
              return setShowContinents(false);
            }}
            applyFilters={() => {
              return applyFilters();
            }}
            setValues={handleChange}
            setCountries={handleCountryChange}
            setCountriesArray={updateCountries}
            setAllCountries={updateCountriesList}
            hasChildren
            currentContinentId={currentContinentId}
            locale={locale}
            clearFilters={() => {
              return clearSelection(checkedCountries, 'continents');
            }}
            preselectedContinentId={preselectedContinentId}
          />

          <Dropdown
            title={t('businessProposal.location')}
            showDropdown={showLocations}
            category="locations"
            selectedArray={checkedLocations}
            locale={locale}
            array={locations}
            onShowDropdown={() => {
              return setShowLocations(true);
            }}
            onHideDropdown={() => {
              return setShowLocations(false);
            }}
            applyFilters={() => {
              return applyFilters();
            }}
            setValues={handleChange}
            hasChildren={false}
            clearFilters={() => {
              return clearSelection(null, 'locations');
            }}
          />

          <Dropdown
            title={t('businessProposal.industry')}
            showDropdown={showIndustries}
            category="industries"
            selectedArray={checkedIndustries}
            locale={locale}
            array={industries}
            onShowDropdown={() => {
              return setShowIndustries(true);
            }}
            onHideDropdown={() => {
              return setShowIndustries(false);
            }}
            applyFilters={() => {
              return applyFilters();
            }}
            setValues={handleChange}
            hasChildren={false}
            clearFilters={() => {
              return clearSelection(null, 'industries');
            }}
          />

          <Dropdown
            title={t('businessProposal.stages')}
            showDropdown={showStages}
            category="stages"
            selectedArray={checkedStages}
            locale={locale}
            array={stages}
            onShowDropdown={() => {
              return setShowStages(true);
            }}
            onHideDropdown={() => {
              return setShowStages(false);
            }}
            applyFilters={() => {
              return applyFilters();
            }}
            setValues={handleChange}
            hasChildren={false}
            clearFilters={() => {
              return clearSelection(null, 'stages');
            }}
          />

          <Dropdown
            title={t('businessProposal.languages')}
            showDropdown={showLanguages}
            category="languages"
            selectedArray={checkedLanguages}
            locale={locale}
            array={languages}
            onShowDropdown={() => {
              return setShowLanguages(true);
            }}
            onHideDropdown={() => {
              return setShowLanguages(false);
            }}
            applyFilters={() => {
              return applyFilters();
            }}
            setValues={handleChange}
            hasChildren={false}
            clearFilters={() => {
              return clearSelection(null, 'languages');
            }}
          />

          {showTaxRelief && (
            <Dropdown
              title={t('businessProposal.taxRelief')}
              showDropdown={showTaxReliefs}
              category="taxRelief"
              selectedArray={checkedTaxreliefs}
              locale={locale}
              array={taxReliefs}
              onShowDropdown={() => {
                return setShowTaxReliefs(true);
              }}
              onHideDropdown={() => {
                return setShowTaxReliefs(false);
              }}
              applyFilters={() => {
                return applyFilters();
              }}
              setValues={handleChange}
              hasChildren={false}
              clearFilters={() => {
                return clearSelection(null, 'taxRelief');
              }}
            />
          )}

          <Dropdown
            title={t('businessProposal.fundingType')}
            showDropdown={showFundingTypes}
            category="funding"
            selectedArray={checkedFundingTypes}
            locale={locale}
            array={fundingTypes}
            onShowDropdown={() => {
              return setShowFundingTypes(true);
            }}
            onHideDropdown={() => {
              return setShowFundingTypes(false);
            }}
            applyFilters={() => {
              return applyFilters();
            }}
            setValues={handleChange}
            hasChildren={false}
            clearFilters={() => {
              return clearSelection(null, 'funding');
            }}
          />

          <ListButton
            title={t('businessProposal.clearFilters')}
            link
            onClick={clearFilters}
          />
        </div>
        <div className="w-full flex flex-wrap items-start md:gap-0 gap-y-3 px-4 my-4">
          <div className="md:w-3/4 w-full h-full">
            <TagsInput
              handleQueryInput={searchWithQuery}
              isEmpty={isTagsEmpty}
              preEnteredTags={preEnteredTag || null}
              unsetPreRenderedTag={unsetPreRenderedTag}
              storeInput={storeAdditionalInput}
            />
          </div>
          <div className="md:w-1/4 w-full md:px-3">
            <button
              type="submit"
              onClick={searchWithTags}
              className="bg-blue-400 text-white w-full flex items-center justify-center max-h-20 h-full px-4 py-3 rounded-md hover:bg-blue-500"
            >
              {searching ? (
                <svg
                  className="animate-spin inline h-3.5 w-3.5 text-white fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <title>loading</title>
                  <path
                    className="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                t('businessProposal.apply')
              )}
            </button>
          </div>
        </div>
        <div className="w-full text-center px-4 my-5">
          {pageHeader && (
            <h1 className="font-medium text-gray-800 mb-3 pt-10 lg:text-4xl text-2xl">
              {pageHeader}
            </h1>
          )}
          {pageDescription && (
            <p className="text-base text-brand-lightgray">{pageDescription}</p>
          )}
        </div>
        {preRenderedData.ordering && (
          <div className="w-full p-4 flex justify-between items-center">
            <p className="text-base text-gray-700">
              {totalResults} {t('businessProposal.investmentsFound')}
            </p>
            <div className="inline-flex items-center gap-x-3">
              <span className="text-base text-gray-700 whitespace-nowrap">
                {t('businessProposal.displayBy')}
              </span>
              <Select
                onChange={handleSelectChange}
                options={preRenderedData.ordering.availableOptions}
                selected={preRenderedData.ordering.selectedOption}
              />
            </div>
          </div>
        )}
        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 p-4">
          {isLoggedIn ? (
            updatedPageNumber === 1 ? (
              loading ? (
                skeletonCards.map((card, i) => {
                  return <Skeleton key={`${card}-${i}`} />;
                })
              ) : (
                <>
                  {proposals.map((proposal, index) => {
                    return (
                      <ProposalSummary
                        key={proposal.proposalUri}
                        proposal={proposal}
                        locale={locale}
                        deleteUri={
                          isLoggedIn
                            ? proposal?.proposalUserActions?.blockProposalUri
                            : false
                        }
                        shortlistUri={
                          isLoggedIn
                            ? proposal?.proposalUserActions
                                ?.addShortlistedProposalUri ||
                              proposal?.proposalUserActions
                                ?.removeShortlistedProposalUri
                            : false
                        }
                        removeProposalFromArray={() => {
                          return removeProposal(index);
                        }}
                        currencyCode={currencyCode}
                      />
                    );
                  })}
                </>
              )
            ) : (
              <>
                {proposals.map((proposal, index) => {
                  return (
                    <ProposalSummary
                      key={proposal.proposalUri}
                      proposal={proposal}
                      locale={locale}
                      deleteUri={
                        isLoggedIn
                          ? proposal?.proposalUserActions?.blockProposalUri
                          : false
                      }
                      shortlistUri={
                        isLoggedIn
                          ? proposal?.proposalUserActions
                              ?.addShortlistedProposalUri ||
                            proposal?.proposalUserActions
                              ?.removeShortlistedProposalUri
                          : false
                      }
                      removeProposalFromArray={() => {
                        return removeProposal(index);
                      }}
                      currencyCode={currencyCode}
                    />
                  );
                })}
                {loading &&
                  skeletonCards.map((card, i) => {
                    return <Skeleton key={`${card}-${i}`} />;
                  })}
              </>
            )
          ) : loading ? (
            skeletonCards.map((card, i) => {
              return <Skeleton key={`${card}-${i}`} />;
            })
          ) : (
            proposals.map((proposal, index) => {
              return (
                <ProposalSummary
                  key={proposal.proposalUri}
                  proposal={proposal}
                  locale={locale}
                  deleteUri={
                    isLoggedIn
                      ? proposal?.proposalUserActions?.blockProposalUri
                      : false
                  }
                  shortlistUri={
                    isLoggedIn
                      ? proposal?.proposalUserActions
                          ?.addShortlistedProposalUri ||
                        proposal?.proposalUserActions
                          ?.removeShortlistedProposalUri
                      : false
                  }
                  removeProposalFromArray={() => {
                    return removeProposal(index);
                  }}
                  currencyCode={currencyCode}
                />
              );
            })
          )}
        </div>
      </div>
      {showNoResultsLabel && (
        <div className="mt-8 mb-24">{t('portfolio.noProposals')}</div>
      )}
      {isLoggedIn && showLoadMoreButton && (
        <div className="mt-8 mb-24">
          <LoadMore
            title={t('portfolio.button')}
            onClick={loadMoreProposals}
            loading={loading}
          />
        </div>
      )}
      {!isLoggedIn && (
        <div className="w-full lg:max-w-screen-xl mt-8 mb-24">
          <Pagination
            currentPage={pageNumber}
            totalResults={totalResults}
            pageSize={preRenderedData.pagination.pageSize}
            totalPages={parseInt(lastPageNumber, 10)}
            paginationUrl={businessProposalUrl}
          />
        </div>
      )}
    </div>
  );
};

export default BusinessProposalPage;

BusinessProposalPage.propTypes = {
  selectedIndustryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastPageNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preFetchedReponse: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  locale: PropTypes.string,
  apiUrl: PropTypes.string,
  industriesUrl: PropTypes.string,
  stagesUrl: PropTypes.string,
  languagesUrl: PropTypes.string,
  fundingTypesUrl: PropTypes.string,
  taxReliefUrl: PropTypes.string,
  locationsUrl: PropTypes.string,
  searchBarQuery: PropTypes.string,
  currencyCode: PropTypes.string,
  maxInvestmentLimit: PropTypes.number,
  topLocationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultPageSize: PropTypes.number,
  displayLoadMoreButton: PropTypes.bool.isRequired,
  siteCode: PropTypes.string,
  preSetPageNumber: PropTypes.number,
  pageHeader: PropTypes.string,
  pageDescription: PropTypes.string,
  selectedLocationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedLocationType: PropTypes.string,
  investmentRange: PropTypes.instanceOf(Object),
  businessProposalUrl: PropTypes.string.isRequired
};

BusinessProposalPage.defaultProps = {
  selectedIndustryId: null,
  lastPageNumber: 0,
  preFetchedReponse: '',
  isLoggedIn: false,
  locale: '',
  apiUrl: '',
  industriesUrl: '',
  stagesUrl: '',
  languagesUrl: '',
  fundingTypesUrl: '',
  taxReliefUrl: '',
  locationsUrl: '',
  searchBarQuery: '',
  currencyCode: '',
  maxInvestmentLimit: 0,
  topLocationId: null,
  defaultPageSize: 0,
  siteCode: '',
  preSetPageNumber: 0,
  pageHeader: '',
  pageDescription: '',
  selectedLocationId: null,
  selectedLocationType: '',
  investmentRange: null
};
