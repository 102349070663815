import React from 'react';
import * as PropTypes from 'prop-types';
import EmblaCarousel from '../Carousel/EmblaCarousel';

const ProposalCarouselBlock = ({
  businessProposalUri,
  cardOrientation,
  currencyCode,
  isLoggedIn,
  locale,
  proposals,
  title
}) => {
  const proposalsArray =
    typeof proposals === 'string'
      ? JSON.parse(proposals)?.slice(0, 3)
      : proposals;

  return (
    <div className="w-full py-16">
      <h3 className="text-2xl text-gray-800 font-medium mb-7">{title}</h3>
      <EmblaCarousel
        slides={proposalsArray}
        options={{ loop: false }}
        businessProposalUri={businessProposalUri}
        cardOrientation={cardOrientation}
        currencyCode={currencyCode}
        isLoggedIn={isLoggedIn}
        locale={locale}
      />
    </div>
  );
};

export default ProposalCarouselBlock;

ProposalCarouselBlock.propTypes = {
  businessProposalUri: PropTypes.string.isRequired,
  cardOrientation: PropTypes.oneOf(['default', 'horizontal']),
  currencyCode: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  proposals: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array)
  ]).isRequired,
  title: PropTypes.string.isRequired
};

ProposalCarouselBlock.defaultProps = {
  cardOrientation: 'default',
  isLoggedIn: false
};
