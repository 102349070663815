import React from 'react';
import * as PropTypes from 'prop-types';

const NoInvestorFound = ({ message, addPitchUri }) => {
  return (
    <div className="max-w-sm rounded-md flex flex-col p-6">
      <div className="text-center bg-blue-50 text-gray-500 border-2 border-dashed border-blue-100 rounded-xl h-full">
        <div className="p-16">
          <div className="text-xl w-full text-center whitespace-pre-wrap">
            {message}
          </div>
          <img src="/images/small/curve-arrow-28X84.png" alt="curved arrow" />
          <div className="flex justify-center">
            <a
              href={addPitchUri}
              className="btn--primary cursor-pointer rounded-full w-28 h-28 text-white text-7xl flex justify-center items-center"
            >
              &#65291;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoInvestorFound;

NoInvestorFound.propTypes = {
  message: PropTypes.string.isRequired,
  addPitchUri: PropTypes.string.isRequired
};
