/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../Util/i18n';
import { fetchInvestors } from '../../Util/ApiService';
import InvestorCard from '../../Components/InvestorCard/InvestorCard';
import NoInvestorFound from '../../Components/MyInvestors/NoInvestorFound';
import LoadMore from '../../Components/Buttons/LoadMore';

const PAGE_SIZE = 6;

const InvestorsPage = ({
  locale,
  apiUrl,
  investorSearchUrl,
  addProposalUrl,
  interestedInMyProposal,
  myShortlisted,
  myNudged,
  noProposals,
  currencyCode,
  proposalId,
  noMaxWidth = false
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [investors, setInvestors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [showNoResultsLabel, setShowNoResultsLabel] = useState(false);

  const { t } = useTranslation();

  const getInvestors = async () => {
    setLoading(true);
    const newInvestors = await fetchInvestors(apiUrl, {
      pageNumber,
      pageSize: PAGE_SIZE,
      interestedInMyProposal,
      myShortlisted,
      myNudged,
      proposalId
    });
    const totalResults = Number(newInvestors.pagination.totalResults) || 0;
    if (!totalResults) {
      setShowNoResultsLabel(true);
    }
    setPageNumber(pageNumber + 1);
    setInvestors([...investors.concat(newInvestors.results)]);
    setShowLoadMoreButton(totalResults > pageNumber * PAGE_SIZE);
    setLoading(false);
  };

  useEffect(() => {
    getInvestors();
  }, []);

  return (
    <div className="w-full flex flex-col items-center mt-8 h-full">
      <div className={`${noMaxWidth ? 'w-full' : 'max-w-screen-2xl'}`}>
        <div className="w-full grid lg:grid-cols-3 grid-cols-1">
          {investors.map((investor) => {
            return interestedInMyProposal ? (
              <InvestorCard
                key={investor.detailsUrl}
                investor={investor}
                locale={locale}
                currencyCode={currencyCode}
                shortlistUri={
                  myShortlisted
                    ? investor?.investorUserActions
                        ?.addShortlistedInvestorUri ||
                      investor?.investorUserActions
                        ?.removeShortlistedInvestorUri
                    : false
                }
                isLoggedIn
              />
            ) : (
              <InvestorCard
                key={investor.detailsUrl}
                investor={investor}
                locale={locale}
                currencyCode={currencyCode}
                shortlistUri={
                  myShortlisted
                    ? investor?.investorUserActions
                        ?.addShortlistedInvestorUri ||
                      investor?.investorUserActions
                        ?.removeShortlistedInvestorUri
                    : false
                }
              />
            );
          })}
        </div>
      </div>
      {showLoadMoreButton && (
        <div className="mt-16 mb-24">
          <LoadMore
            title={t('investorCard.button')}
            onClick={getInvestors}
            loading={loading}
          />
        </div>
      )}
      {showNoResultsLabel && interestedInMyProposal && noProposals && (
        <NoInvestorFound
          locale={locale}
          addPitchUri={addProposalUrl}
          message={t('myInvestors.noProposals')}
        />
      )}
      {showNoResultsLabel && interestedInMyProposal && !noProposals && (
        <NoInvestorFound
          locale={locale}
          addPitchUri={investorSearchUrl}
          message={t('myInvestors.noInterested')}
        />
      )}
      {showNoResultsLabel && myShortlisted && (
        <NoInvestorFound
          locale={locale}
          addPitchUri={investorSearchUrl}
          message={t('myInvestors.noShortlisted')}
        />
      )}
      {showNoResultsLabel && myNudged && (
        <NoInvestorFound
          locale={locale}
          addPitchUri={investorSearchUrl}
          message={t('myInvestors.noNudged')}
        />
      )}
    </div>
  );
};

export default InvestorsPage;

InvestorsPage.propTypes = {
  locale: PropTypes.string,
  apiUrl: PropTypes.string,
  investorSearchUrl: PropTypes.string,
  addProposalUrl: PropTypes.string,
  interestedInMyProposal: PropTypes.bool,
  myShortlisted: PropTypes.bool,
  myNudged: PropTypes.bool,
  noProposals: PropTypes.bool,
  currencyCode: PropTypes.string,
  proposalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noMaxWidth: PropTypes.bool
};

InvestorsPage.defaultProps = {
  locale: '',
  apiUrl: '',
  investorSearchUrl: '',
  addProposalUrl: '',
  interestedInMyProposal: false,
  myShortlisted: false,
  myNudged: false,
  noProposals: false,
  currencyCode: '',
  proposalId: null,
  noMaxWidth: false
};
