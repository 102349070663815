import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import ProposalSummary from '../../Components/ProposalSummary/ProposalSummary';
import LoadMore from '../../Components/Buttons/LoadMore';
import { fetchProposals } from '../../Util/ApiService';
import { removeEntryFromArray } from '../../Util/data';

const PAGE_SIZE = 6;

const MyMatchesPage = ({ locale, apiUrl, currencyCode }) => {
  // Set page number to 1 initially
  const [pageNumber, setPageNumber] = useState(1);
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [showNoResultsLabel, setShowNoResultsLabel] = useState(false);

  const { t } = useTranslation();

  const getProposals = async () => {
    setLoading(true);
    const newProposals = await fetchProposals(apiUrl, {
      pageNumber,
      pageSize: PAGE_SIZE,
      myMatches: true
    });
    const totalResults = Number(newProposals.pagination.totalResults) || 0;
    if (!totalResults) {
      setShowNoResultsLabel(true);
    }
    setPageNumber(pageNumber + 1);
    setProposals([...proposals.concat(newProposals.results)]);
    setShowLoadMoreButton(totalResults > pageNumber * PAGE_SIZE);
    setLoading(false);
  };

  const removeProposal = (index) => {
    const proposalsArray = [...proposals];
    const newArray = removeEntryFromArray(proposalsArray, index);
    setProposals([...newArray]);
  };

  useEffect(() => {
    // On page load first get proposals:
    getProposals();
  }, []);

  return (
    <div className="w-full flex flex-col items-center mt-8 h-full">
      <div className="mx-auto max-w-screen-xl w-full">
        <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-4 p-4">
          {proposals.map((proposal, index) => {
            return (
              <ProposalSummary
                key={proposal.title}
                proposal={proposal}
                locale={locale}
                deleteUri={proposal?.proposalUserActions?.blockProposalUri}
                shortlistUri={
                  proposal?.proposalUserActions?.addShortlistedProposalUri ||
                  proposal?.proposalUserActions?.removeShortlistedProposalUri
                }
                removeProposalFromArray={() => {
                  return removeProposal(index);
                }}
                currencyCode={currencyCode}
              />
            );
          })}
        </div>
      </div>
      {showLoadMoreButton && (
        <div className="mt-8 mb-24">
          <LoadMore
            title={t('portfolio.button')}
            onClick={getProposals}
            loading={loading}
          />
        </div>
      )}
      {showNoResultsLabel && (
        <div className="mt-8 mb-24">{t('portfolio.noProposals')}</div>
      )}
    </div>
  );
};

export default MyMatchesPage;

MyMatchesPage.propTypes = {
  locale: PropTypes.string,
  apiUrl: PropTypes.string,
  currencyCode: PropTypes.string
};

MyMatchesPage.defaultProps = {
  locale: '',
  apiUrl: '',
  currencyCode: ''
};
