/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProposalSummary from '../ProposalSummary/ProposalSummary';
import Skeleton from '../Card/Skeleton';
import { removeEntryFromArray } from '../../Util/data';
import { connectionStatusCodes } from '../../Util/connectionStatuses';

const DashboardBlock = ({
  businessProposalUri,
  connections,
  currencyCode,
  fromConnections,
  inboxPageUri,
  isLoggedIn,
  loading,
  locale,
  noResultsMessage,
  noResultsTitle,
  proposals,
  updateProposalList
}) => {
  const { t } = useTranslation();
  const skeletonCards = Array(3).fill(<Skeleton />);
  const [preFetchProposals, setPreFetchedProposals] = useState(proposals);
  const [preFetchedConnections, setPreFetchedConnections] =
    useState(connections);

  const removeProposal = (index, array, isConnection = false) => {
    const proposalsArray = [...array];
    const newArray = removeEntryFromArray(proposalsArray, index);
    if (isConnection) {
      setPreFetchedConnections([...newArray]);
    } else {
      setPreFetchedProposals([...newArray]);
    }
    updateProposalList(newArray);
  };

  useEffect(() => {
    setPreFetchedProposals(proposals);
    setPreFetchedConnections(connections);
  }, [proposals, connections]);

  return (
    <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 pt-7">
      {loading ? (
        skeletonCards?.map((card, index) => {
          return <Skeleton key={index} />;
        })
      ) : preFetchedConnections?.length ? (
        preFetchedConnections?.map((connection, idx) => {
          return (
            <ProposalSummary
              key={idx}
              proposal={connection?.proposal}
              locale={locale}
              currencyCode={currencyCode}
              isFromConnection={fromConnections}
              inboxPageUri={inboxPageUri}
              deleteUri={
                connection?.status.code ===
                connectionStatusCodes.pendingAcceptance
                  ? connection?.connectionUserActions?.rejectConnectionUri
                  : connection?.connectionUserActions?.markAsArchivedUri
              }
              removeProposalFromArray={() => {
                removeProposal(idx, connections, true);
              }}
            />
          );
        })
      ) : preFetchProposals?.length ? (
        preFetchProposals?.map((proposal, idx) => {
          return (
            <ProposalSummary
              key={idx}
              proposal={proposal}
              locale={locale}
              currencyCode={currencyCode}
              shortlistUri={
                isLoggedIn
                  ? proposal.proposalUserActions?.addShortlistedProposalUri ||
                    proposal?.proposalUserActions?.removeShortlistedProposalUri
                  : false
              }
              removeProposalFromArray={() => {
                removeProposal(idx, preFetchProposals);
              }}
              isFromConnection={fromConnections}
              inboxPageUri={inboxPageUri}
            />
          );
        })
      ) : (
        <div
          role="alert"
          className="col-span-3 bg-blue-100 p-3 flex items-start gap-4"
        >
          <span className="material-icons text-blue-800">error</span>
          <div className="w-full flex md:flex-row md:justify-between md:items-center flex-col gap-2.5">
            <div className="flex flex-col text-blue-800">
              <h6 className="font-semibold text-base leading-6">
                {noResultsTitle}
              </h6>
              <span className="text-base font-normal leading-6">
                {noResultsMessage}
              </span>
            </div>
            {noResultsTitle !==
              t(
                'investorDashboard.connectionRequests.noConnectionRequests'
              ) && (
              <a
                href={businessProposalUri}
                className="bg-blue-600 hover:bg-blue-500 py-2 px-4 rounded-md text-base leading-6 font-semibold text-white max-w-max whitespace-nowrap"
              >
                {t('investorDashboard.viewPitches')}
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardBlock;

DashboardBlock.propTypes = {
  businessProposalUri: PropTypes.string.isRequired,
  connections: PropTypes.instanceOf(Array),
  currencyCode: PropTypes.string,
  fromConnections: PropTypes.bool,
  inboxPageUri: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  locale: PropTypes.string,
  noResultsMessage: PropTypes.string,
  noResultsTitle: PropTypes.string,
  proposals: PropTypes.instanceOf(Array),
  updateProposalList: PropTypes.func
};

DashboardBlock.defaultProps = {
  connections: [],
  currencyCode: '',
  fromConnections: false,
  inboxPageUri: '',
  isLoggedIn: false,
  loading: false,
  locale: '',
  noResultsMessage: '',
  noResultsTitle: '',
  proposals: [],
  updateProposalList: () => {}
};
