/* eslint-disable indent */
import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import useEmblaCarousel from 'embla-carousel-react';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import CarouselButton from './CarouselButton';
import ProposalSummary from '../ProposalSummary/ProposalSummary';
import ProposalPlaceholder from '../Card/ProposalPlaceholder/ProposalPlaceholder';
import { removeEntryFromArray } from '../../Util/data';

const EmblaCarousel = ({
  businessProposalUri,
  cardOrientation,
  currencyCode,
  isLoggedIn,
  locale,
  options,
  slides
}) => {
  const [slidesArray, setSlidesArray] = useState(slides);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const { t } = useTranslation();

  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  });

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  });

  const removeProposal = (index) => {
    const proposalsArray = [...slides];
    const newArray = removeEntryFromArray(proposalsArray, index);
    setSlidesArray([...newArray]);
  };

  const detectScroll = () => {
    if (emblaApi?.canScrollNext()) {
      setNextBtnDisabled(false);
    } else {
      setNextBtnDisabled(true);
    }

    if (emblaApi.canScrollPrev()) {
      setPrevBtnDisabled(false);
    } else {
      setPrevBtnDisabled(true);
    }
  };

  useEffect(() => {
    if (emblaApi) emblaApi.on('scroll', detectScroll);
  }, [emblaApi]);

  return (
    <section className="embla relative w-full h-full overflow-hidden py-6">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container flex w-full h-full gap-4">
          {slidesArray?.length ? (
            <>
              {slidesArray?.map((proposal, index) => {
                return (
                  <div
                    className={`embla__slide max-w-full ${
                      cardOrientation === 'horizontal'
                        ? 'md:min-w-4/5 min-w-full'
                        : 'md:min-w-sm'
                    }`}
                    key={proposal.proposalUri}
                  >
                    <ProposalSummary
                      proposal={proposal}
                      locale={locale}
                      cardOrientation={cardOrientation}
                      currencyCode={currencyCode}
                      deleteUri={
                        isLoggedIn
                          ? proposal?.proposalUserActions?.blockProposalUri
                          : null
                      }
                      shortlistUri={
                        isLoggedIn
                          ? proposal?.proposalUserActions
                              ?.addShortlistedProposalUri ||
                            proposal?.proposalUserActions
                              ?.removeShortlistedProposalUri
                          : null
                      }
                      removeProposalFromArray={() => {
                        return removeProposal(index);
                      }}
                    />
                  </div>
                );
              })}
              {cardOrientation !== 'horizontal' && (
                <div className="embla__slide md:min-w-sm max-w-full">
                  <ProposalPlaceholder
                    buttonUri={businessProposalUri}
                    buttonText={t('explorePage.seeMore')}
                  />
                </div>
              )}
            </>
          ) : (
            <span />
          )}
        </div>
      </div>

      {slides?.length >= 2 && (
        <>
          {!prevBtnDisabled && (
            <CarouselButton direction="left" onClick={scrollPrev} />
          )}
          {!nextBtnDisabled && <CarouselButton onClick={scrollNext} />}
        </>
      )}
    </section>
  );
};

export default EmblaCarousel;

EmblaCarousel.propTypes = {
  businessProposalUri: PropTypes.string.isRequired,
  cardOrientation: PropTypes.oneOf(['default', 'horizontal']),
  currencyCode: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  slides: PropTypes.instanceOf(Array).isRequired
};

EmblaCarousel.defaultProps = {
  cardOrientation: 'default',
  isLoggedIn: false
};
