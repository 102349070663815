/* eslint-disable no-undef */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../Components/InboxRevamp/Sidebar';
import MessageSection from '../../Components/InboxRevamp/MessageSection';
import UserInfo from '../../Components/InboxRevamp/UserInfo';
import {
  fetchMessages,
  fetchMessageById,
  sendMessage,
  messageAction,
  fetchConnectionSummary
} from '../../Util/ApiService';
import '../../Util/i18n';
import { connectionStatusCodes } from '../../Util/connectionStatuses';
import { getLastCharacter, removeEntryFromArray } from '../../Util/data';

const InboxRevamp = ({
  locale,
  apiUrl,
  connectionId,
  acceptNudge,
  rejectNudge,
  mercureLink,
  maxUploadDocumentSize
}) => {
  const [PAGE_SIZE, SET_PAGE_SIZE] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [messagePageNumber, setMessagePageNumber] = useState(1);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNoResultsLabel, setShowNoResultsLabel] = useState(false);
  const [sending, setSending] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(true);
  const [showProfileDetails, setShowProfileDetails] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [selectedRecipientId, setSelectedRecipientId] = useState(null);
  const [selectedProposalTitle, setSelectedProposalTitle] = useState(null);
  const [selectedRecipientImage, setSelectedRecipientImage] = useState(null);
  const [updatedReadStatus, setUpdatedReadStatus] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [messageAttachments, setMessageAttachments] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [additionalParam, setAdditionalParam] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [inboxHeight, setInboxHeight] = useState(null);
  const [acceptingNudge, setAccepting] = useState(false);
  const [rejectingNudge, setRejecting] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);

  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  const getMessages = async (newPageNumber) => {
    const newMessages = additionalParam
      ? await fetchMessages(`${apiUrl}?${additionalParam}`, {
          pageNumber: newPageNumber,
          pageSize: PAGE_SIZE,
          searchQuery
        })
      : await fetchMessages(apiUrl, {
          pageNumber: newPageNumber,
          pageSize: PAGE_SIZE,
          searchQuery
        });
    // eslint-disable-next-line no-unused-expressions
    newPageNumber === 1
      ? setMessages(newMessages.results)
      : setMessages([...messages, ...newMessages.results]);
    setTotalResults(newMessages.pagination.pageNumber * PAGE_SIZE);

    if (newPageNumber === 1 && !newMessages.results.length) {
      setShowNoResultsLabel(true);
    }

    if (!newMessages.results.length) {
      setLoading(false);
      return setHasMoreMessages(false);
    }

    if (newMessages.pagination.totalResults === 0) {
      setHasMoreMessages(false);
      setLoading(false);
      return setShowNoResultsLabel(true);
    }

    setPageNumber(newPageNumber);

    const initialIdx = 0;
    let thread = newMessages.results[initialIdx];

    if (connectionId) {
      const selectedThread = await fetchConnectionSummary(
        `/connection/${connectionId}/summary.json`
      );
      if (selectedThread.archived) {
        setMessages([]);
        setMessages([...messages, selectedThread]);
        thread = selectedThread;
      } else {
        const isFound = newMessages.results.some((message) => {
          return message.connectionId === connectionId;
        });

        if (isFound) {
          thread = newMessages.results.find((mess) => {
            return mess.connectionId === connectionId;
          });
        } else {
          setMessages([selectedThread, ...messages]);
          thread = selectedThread;
        }
      }
    }

    if (thread.status.code === connectionStatusCodes.rejected) {
      setLoading(false);
      return setConversationLoading(false);
    }

    setLoading(false);
    setConversationLoading(false);
    setShowNoResultsLabel(false);
  };

  const getMessageById = async (e, page) => {
    const newMessage = await fetchMessageById(
      `${e}?pageSize=${PAGE_SIZE}&pageNumber=${page}`
    );
    if (!newMessage.results.length) {
      return;
    }
    setSelectedConversation(newMessage);
    setConversationLoading(false);
    const tempAttachmentArray = [];

    if (page !== 1) {
      messageAttachments.forEach((item) => {
        return tempAttachmentArray.push(item);
      });
    }
    newMessage.results.forEach((message) => {
      if (message?.attachments?.length) {
        message.attachments.forEach((attachment) => {
          tempAttachmentArray.push(attachment);
        });
        setMessageAttachments(tempAttachmentArray);
      }
    });
    setMessagePageNumber(page);
  };

  // infinite loading inside message section
  const fetchPreviousMessages = () => {
    getMessageById(selectedConnection.messagesApiUri, messagePageNumber + 1);
  };

  // infinite loading on sidebar
  const fetchMoreMessages = () => {
    getMessages(pageNumber + 1);
  };

  const toggleProfileDetails = () => {
    setShowProfileDetails((value) => {
      return !value;
    });
    setShowSidebar(false);
  };

  const updateSelection = (
    e,
    recipient,
    proposalTitle,
    recipientImage,
    recipientId,
    updatedConnectionId
  ) => {
    setSelectedConversationId(updatedConnectionId);
    setSelectedRecipient(recipient);
    setSelectedRecipientId(recipientId);
    setSelectedProposalTitle(proposalTitle);
    setSelectedRecipientImage(recipientImage);
    if (!e) {
      setSelectedConversation(null);
      setShowSidebar(false);
    } else {
      getMessageById(e, 1);
    }
    setShowSidebar(false);
  };

  const updateMessage = async (message, attachments) => {
    setSending(true);
    const formData = new FormData();
    formData.append('content', message);
    attachments.forEach((attachment) => {
      formData.append('attachments[]', attachment, attachment.name);
    });
    // eslint-disable-next-line no-shadow
    const updatedMessage = messages.find((message) => {
      return message.connectionId === selectedConversationId;
    });
    await sendMessage(`${updatedMessage.postMessageUri}`, formData).then(() => {
      // eslint-disable-next-line no-constant-condition
      if ((selectedConnection.messagesApiUri, 1)) {
        getMessageById(selectedConnection.messagesApiUri, 1);
      }
    });
    setSending(false);
  };

  const updateReadStatus = async (val, toggleSidebar, selectedMessage) => {
    setUpdatedReadStatus(val);
    const updatedMessage =
      selectedMessage ||
      messages?.find((message) => {
        return message?.connectionId === selectedConversationId;
      });
    if (val) {
      await messageAction(updatedMessage?.connectionUserActions?.markAsReadUri);
    } else {
      await messageAction(
        updatedMessage?.connectionUserActions?.markAsNotReadUri
      );
    }
    setHasMoreMessages(true);
    if (!toggleSidebar) return;
    setShowSidebar(true);
  };

  const updateArchiveStatus = async (uri) => {
    const updatedConnection = messages.find((connection) => {
      return (
        connection?.connectionUserActions?.markAsArchivedUri === uri ||
        connection?.connectionUserActions?.markAsNotArchivedUri === uri
      );
    });
    await messageAction(uri).then(() => {
      return removeEntryFromArray(
        messages,
        messages.indexOf(updatedConnection)
      );
    });
    if (messages.length) {
      const firstMessage = messages[0];
      updateSelection(
        firstMessage?.messagesApiUri,
        `${firstMessage?.interlocutor?.name?.firstName} ${firstMessage?.interlocutor?.name?.lastName}`,
        firstMessage?.proposal?.title,
        firstMessage?.interlocutor?.logoUri,
        firstMessage?.interlocutor?.userId,
        firstMessage?.connectionId
      );
    } else {
      setShowNoResultsLabel(true);
    }
    setShowSidebar(true);
  };

  const archiveConnection = () => {
    updateArchiveStatus(
      selectedConnection.connectionUserActions.markAsArchivedUri
    );
  };

  const restoreConnection = () => {
    updateArchiveStatus(
      selectedConnection.connectionUserActions.markAsNotArchivedUri
    );
  };

  // accept or reject connection
  const connectionAction = async (uri) => {
    // eslint-disable-next-line no-unused-expressions
    uri.includes(true) ? setAccepting(true) : setRejecting(true);
    await messageAction(uri).then(() => {
      if (uri.includes(true)) {
        getMessageById(selectedConnection?.messagesApiUri, 1);
        selectedConnection.status.code = connectionStatusCodes.new;
      } else {
        removeEntryFromArray(messages, messages.indexOf(selectedConnection));
        const firstMessage = messages[0];
        updateSelection(
          firstMessage?.messagesApiUri,
          `${firstMessage?.interlocutor?.name?.firstName} ${firstMessage?.interlocutor?.name?.lastName}`,
          firstMessage?.proposal?.title,
          firstMessage?.interlocutor?.logoUri,
          firstMessage?.interlocutor?.userId,
          firstMessage?.connectionId
        );
      }
    });
    setHasMoreMessages(true);
    setAccepting(false);
    setRejecting(false);
    setShowSidebar(true);
  };

  // inbox filters
  const updateFilter = (e) => {
    switch (e) {
      case 'all':
        setAdditionalParam(null);
        setSearchQuery('');
        setSelectedRecipient(null);
        setSelectedRecipientId(null);
        setSelectedProposalTitle(null);
        setSelectedConversationId(null);
        setSelectedRecipientImage(null);
        break;
      case 'deleted':
        setAdditionalParam('archivedOnly=true');
        setSelectedRecipient(null);
        setSelectedRecipientId(null);
        setSelectedProposalTitle(null);
        setSelectedConversationId(null);
        setSelectedRecipientImage(null);
        break;
      case 'read':
        setAdditionalParam('read=true');
        setSelectedRecipient(null);
        setSelectedRecipientId(null);
        setSelectedProposalTitle(null);
        setSelectedConversationId(null);
        setSelectedRecipientImage(null);
        break;
      case 'unread':
        setAdditionalParam('read=false');
        setSelectedRecipient(null);
        setSelectedRecipientId(null);
        setSelectedProposalTitle(null);
        setSelectedConversationId(null);
        setSelectedRecipientImage(null);
        break;
      default:
        setAdditionalParam(null);
    }
  };

  // filter messages by search query
  const filterByQuery = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    setLoading(true);
    setHasMoreMessages(true);
    if (
      additionalParam?.length === undefined &&
      searchQuery?.length === undefined
    ) {
      return;
    }
    getMessages(1);
  }, [additionalParam, searchQuery]);

  useEffect(() => {
    const currentMessage = messages.find((message) => {
      return message.connectionId === selectedConversationId;
    });
    if (!currentMessage?.messagesApiUri && currentMessage !== undefined) {
      updateReadStatus(true, false, currentMessage);
    }
    setSelectedConnection(currentMessage);
    setMessageAttachments([]);
    setSelectedConversation(null);
  }, [selectedConversationId]);

  useEffect(() => {
    setUpdatedReadStatus(true);
    if (
      selectedConnection?.status.code ===
      connectionStatusCodes?.pendingAcceptance
    ) {
      if (acceptNudge) {
        connectionAction(
          selectedConnection.connectionUserActions.acceptConnectionUri
        );
      }
      if (rejectNudge) {
        connectionAction(
          selectedConnection.connectionUserActions.rejectConnectionUri
        );
      }
    }
  }, [selectedConnection]);

  useEffect(() => {
    const updateInboxHeight = () => {
      const headerHeight = document.querySelector('header').offsetHeight;
      setInboxHeight(window.innerHeight - headerHeight);
    };

    window.addEventListener('resize', updateInboxHeight);
    updateInboxHeight();
    return () => {
      return window.removeEventListener('resize', updateInboxHeight);
    };
  }, []);

  useEffect(() => {
    if (mercureLink) {
      const eventSource = new EventSource(mercureLink);
      eventSource.onmessage = () => {
        if (selectedConnection?.messagesApiUri) {
          getMessageById(selectedConnection.messagesApiUri, 1);
        }
        return SET_PAGE_SIZE((pageNumber + 1) * PAGE_SIZE);
      };
    }
  });

  useEffect(() => {
    getMessages(1);
  }, [PAGE_SIZE]);

  return (
    <div
      className="w-full flex flex-wrap overflow-hidden"
      style={{ height: `${inboxHeight}px` }}
    >
      <Sidebar
        locale={locale}
        loading={loading}
        conversations={messages}
        updateSelectedConversation={updateSelection}
        activeId={selectedConversationId || ''}
        updatedReadStatus={updatedReadStatus}
        totalMessages={totalResults}
        fetchMoreMessages={() => {
          return fetchMoreMessages();
        }}
        hasMoreData={hasMoreMessages}
        filterMessage={(e) => {
          return updateFilter(e);
        }}
        filterMessageByQuery={(e) => {
          return filterByQuery(e);
        }}
        noResults={showNoResultsLabel}
        updateReadStatus={updateReadStatus}
        markAsArchived={archiveConnection}
        markAsUnarchived={restoreConnection}
        showSidebar={showSidebar}
      />
      {showNoResultsLabel ? (
        <div className="mt-8 mx-auto">{t('auth.inbox.noMessages')}</div>
      ) : !loading &&
        !selectedConversationId &&
        !selectedRecipient &&
        !selectedProposalTitle &&
        !selectedRecipientImage ? (
        <div className="m-auto text-center">
          <span className="material-icons text-gray-400 text-6xl">email</span>
          <p className="text-gray-400 font-normal text-lg">
            {t('auth.inbox.noConnectionSelected')}
          </p>
        </div>
      ) : (
        <>
          <MessageSection
            locale={locale}
            loading={conversationLoading || loading}
            toggleProfileDetails={toggleProfileDetails}
            showProfileDetails={showProfileDetails}
            conversation={selectedConversation || ''}
            handleMessage={updateMessage}
            sending={sending}
            recipient={selectedRecipient}
            recipientUserIdLastChar={
              selectedRecipientId ? getLastCharacter(selectedRecipientId) : '0'
            }
            proposalTitle={selectedProposalTitle}
            avatar={selectedRecipientImage}
            updateReadStatus={updateReadStatus}
            markAsArchived={updateArchiveStatus}
            maxUploadDocumentSize={maxUploadDocumentSize}
            restoreConnection={updateArchiveStatus}
            isRead={selectedConnection ? selectedConnection.read : false}
            isProposalUser={
              selectedConnection
                ? selectedConnection.viewingByProposalUser
                : false
            }
            canViewMessages={
              selectedConnection
                ? selectedConnection.viewerCanViewMessages
                : false
            }
            canPostMessages={
              selectedConnection
                ? selectedConnection.viewerCanPostMessages
                : false
            }
            connection={selectedConnection || null}
            acceptConnection={connectionAction}
            rejectConnection={connectionAction}
            loadMoreMessages={() => {
              return fetchPreviousMessages();
            }}
            acceptingNudge={acceptingNudge}
            rejectingNudge={rejectingNudge}
            isHidden={showSidebar}
            toggleSidebar={() => {
              // eslint-disable-next-line arrow-body-style
              return setShowSidebar((value) => !value);
            }}
            attachmentList={messageAttachments}
            connectionAction={connectionAction}
            interestedUserDeactivated={
              selectedConnection
                ? selectedConnection.interlocutor.deactivated
                : false
            }
            updatedReadStatus={updatedReadStatus}
          />
          <UserInfo
            locale={locale}
            showProfileDetails={showProfileDetails}
            conversation={selectedConnection}
            attachments={messageAttachments}
            acceptConnection={connectionAction}
            rejectConnection={connectionAction}
            isProposalUser={
              selectedConnection
                ? selectedConnection.viewingByProposalUser
                : true
            }
            acceptingNudge={acceptingNudge}
            rejectingNudge={rejectingNudge}
            recipientUserIdLastChar={
              selectedRecipientId ? getLastCharacter(selectedRecipientId) : '0'
            }
          />
        </>
      )}
    </div>
  );
};

export default InboxRevamp;

InboxRevamp.propTypes = {
  locale: PropTypes.string,
  apiUrl: PropTypes.string,
  connectionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool
  ]),
  acceptNudge: PropTypes.bool,
  rejectNudge: PropTypes.bool,
  mercureLink: PropTypes.string,
  maxUploadDocumentSize: PropTypes.number
};

InboxRevamp.defaultProps = {
  locale: '',
  apiUrl: '',
  connectionId: 0,
  acceptNudge: false,
  rejectNudge: false,
  mercureLink: '',
  maxUploadDocumentSize: 0
};
