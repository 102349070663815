/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { fetchMessages, fetchProposals } from '../../Util/ApiService';
import DashboardCard from '../../Components/DashboardCard/DashboardCard';
import GeneralModal from '../../Components/Modal/GeneralModal';
import DashboardBlock from '../../Components/DashboardBlock/DashboardBlock';

const PAGE_SIZE = 50;

const InvestorDashboard = ({
  apiUrl,
  businessProposalUri,
  connectionDailyConnectionUsage,
  currencyCode,
  inboxPageUri,
  investorMessageResponseRate,
  isUserLoggedIn,
  locale,
  proposalsApiUrl,
  connectionDailyConnectionUsagePercentage,
  unreadConnectionCount,
  responseRateLevel,
  responseRateLevelBackgroundColor,
  responseRateLevelTextColor
}) => {
  const { t } = useTranslation();

  const [connections, setConnections] = useState([]);
  const [connectionsLoading, setConnectionsLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalDescription, setModalDescription] = useState(null);
  const [pendingConnections, setPendingConnections] = useState([]);
  const [pendingConnectionsLoading, setPendingConnectionsLoading] =
    useState(true);
  const [proposals, setProposals] = useState([]);
  const [proposalsLoading, setProposalsLoading] = useState(true);
  const [activeTabIndex, setActiveIndex] = useState(1);
  const [numberOfPitches, setNumberOfPitches] = useState(
    pendingConnections.length
  );
  const dashboardCardsWrapper = useRef(null);

  const fetchShortlistedProposals = async () => {
    const results = await fetchProposals(proposalsApiUrl, {
      myShortlisted: true
    });
    if (results) {
      setProposals(results?.results);
      return setProposalsLoading(false);
    }
    return null;
  };

  const getMessages = async () => {
    const messages = await fetchMessages(apiUrl, { pageSize: PAGE_SIZE });
    if (messages) {
      setConnections(messages?.results);
      setNumberOfPitches(messages.length);
      setConnectionsLoading(false);
    }
    setConnectionsLoading(false);
    return setNumberOfPitches(0);
  };

  const getPendingConnections = async () => {
    const pendingConnections = await fetchMessages(apiUrl, {
      pendingAcceptance: true,
      pageSize: PAGE_SIZE
    });
    if (pendingConnections) {
      setNumberOfPitches(pendingConnections.length);
      setPendingConnections(pendingConnections?.results);
      return setPendingConnectionsLoading(false);
    }
    setPendingConnectionsLoading(false);
    return setNumberOfPitches(0);
  };

  const openModal = (title, description) => {
    dashboardCardsWrapper?.current.scrollIntoView();
    setModalTitle(title);
    setModalDescription(description);
    setModalOpen(true);
  };

  const toggleTab = (index) => {
    setActiveIndex(index);
  };

  const updateProposalsList = (updateProposalsFunction, newArray) => {
    setNumberOfPitches(newArray.length);
    updateProposalsFunction(newArray);
  };

  useEffect(() => {
    if (activeTabIndex === 0) setNumberOfPitches(pendingConnections.length);
    else if (activeTabIndex === 1) setNumberOfPitches(connections.length);
    else setNumberOfPitches(proposals.length);
  }, [activeTabIndex]);

  useEffect(() => {
    getMessages();
    getPendingConnections();
    fetchShortlistedProposals();
  }, []);

  return (
    <div className="w-full">
      <div ref={dashboardCardsWrapper} className="bg-white w-full px-3">
        <div className="lg:max-w-screen-xl mx-auto w-full py-8 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-9 gap-4">
          <DashboardCard
            type="connections"
            title={t('investorDashboard.dailyConnections.title')}
            icon={<span className="material-symbols-outlined">group</span>}
            inboxPageUri={inboxPageUri}
            connectionDailyConnectionUsagePercentage={
              connectionDailyConnectionUsagePercentage
            }
            openDescription={() => {
              openModal(
                t('investorDashboard.dailyConnections.title'),
                t('investorDashboard.dailyConnections.description')
              );
            }}
            connectionDailyConnectionUsage={connectionDailyConnectionUsage}
          />

          <DashboardCard
            type="response"
            title={t('investorDashboard.responseRate.title')}
            icon={<span className="material-symbols-outlined">history</span>}
            inboxPageUri={inboxPageUri}
            responseRate={investorMessageResponseRate}
            responseRateLevel={responseRateLevel}
            responseRateLevelBackgroundColor={responseRateLevelBackgroundColor}
            responseRateLevelTextColor={responseRateLevelTextColor}
            openDescription={() => {
              openModal(
                t('investorDashboard.responseRate.title'),
                t('investorDashboard.responseRate.description')
              );
            }}
          />

          <DashboardCard
            type="awaitingResponse"
            title={t('investorDashboard.awaitingResponses.title')}
            icon={<span className="material-symbols-outlined">mail</span>}
            inboxPageUri={inboxPageUri}
            unreadConnections={unreadConnectionCount}
            openDescription={() => {
              openModal(
                t('investorDashboard.awaitingResponses.title'),
                t('investorDashboard.awaitingResponses.description')
              );
            }}
          />
        </div>
        <div className="relative lg:max-w-screen-xl mx-auto flex items-center md:justify-center md:gap-10 gap-6 overflow-auto scrollbar-hide">
          <button
            type="button"
            className={`${
              activeTabIndex === 0
                ? 'border-blue-600 text-gray-800'
                : 'border-transparent text-gray-500'
            } border-b-4 hover:border-blue-600 hover:text-gray-800 py-3.5 font-semibold text-lg leading-7 whitespace-nowrap focus:outline-none`}
            onClick={() => {
              return toggleTab(0);
            }}
          >
            {t('investorDashboard.connectionRequests.title')}
          </button>
          <button
            type="button"
            className={`${
              activeTabIndex === 1
                ? 'border-blue-600 text-gray-800'
                : 'border-transparent text-gray-500'
            } border-b-4 hover:border-blue-600 hover:text-gray-800 py-3.5 font-semibold text-lg leading-7 whitespace-nowrap focus:outline-none`}
            onClick={() => {
              return toggleTab(1);
            }}
          >
            {t('investorDashboard.connections.title')}
          </button>
          <button
            type="button"
            className={`${
              activeTabIndex === 2
                ? 'border-blue-600 text-gray-800'
                : 'border-transparent text-gray-500'
            } border-b-4 hover:border-blue-600 hover:text-gray-800 py-3.5 font-semibold text-lg leading-7 whitespace-nowrap focus:outline-none`}
            onClick={() => {
              return toggleTab(2);
            }}
          >
            {t('investorDashboard.shortlist.title')}
          </button>
          {numberOfPitches > 0 && (
            <div className="xl:block hidden absolute right-0 transform top-1/2 -translate-y-1/2">
              <p className="text-gray-700 text-base leading-7">
                <span className="font-semibold">
                  {numberOfPitches} {t('investorDashboard.pitches')}
                </span>{' '}
                {activeTabIndex === 0
                  ? t('investorDashboard.wouldLikeToConnect')
                  : activeTabIndex === 1
                  ? t('investorDashboard.expressedInterestIn')
                  : t('investorDashboard.savedToShortlist')}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-100 lg:pt-8 pb-16 min-h-screen px-3">
        <div className="lg:max-w-screen-xl mx-auto w-full">
          {numberOfPitches > 0 && (
            <div className="pt-8 xl:hidden">
              <p className="text-gray-700 text-base leading-7">
                <span className="font-semibold">
                  {numberOfPitches} {t('investorDashboard.pitches')}
                </span>{' '}
                {activeTabIndex === 0
                  ? t('investorDashboard.wouldLikeToConnect')
                  : activeTabIndex === 1
                  ? t('investorDashboard.expressedInterestIn')
                  : t('investorDashboard.savedToShortlist')}
              </p>
            </div>
          )}
          {activeTabIndex === 0 ? (
            <DashboardBlock
              businessProposalUri={businessProposalUri}
              connections={pendingConnections}
              currencyCode={currencyCode}
              fromConnections
              inboxPageUri={inboxPageUri}
              loading={pendingConnectionsLoading}
              locale={locale}
              noResultsMessage={t(
                'investorDashboard.connectionRequests.description'
              )}
              noResultsTitle={t(
                'investorDashboard.connectionRequests.noConnectionRequests'
              )}
              updateProposalList={(newList) => {
                return updateProposalsList(setPendingConnections, newList);
              }}
            />
          ) : activeTabIndex === 1 ? (
            <DashboardBlock
              businessProposalUri={businessProposalUri}
              connections={connections}
              currencyCode={currencyCode}
              fromConnections
              inboxPageUri={inboxPageUri}
              loading={connectionsLoading}
              locale={locale}
              noResultsMessage={t('investorDashboard.connections.description')}
              noResultsTitle={t('investorDashboard.connections.noConnections')}
              updateProposalList={(newList) => {
                return updateProposalsList(setConnections, newList);
              }}
            />
          ) : (
            <DashboardBlock
              businessProposalUri={businessProposalUri}
              proposals={proposals}
              currencyCode={currencyCode}
              fromConnections={false}
              inboxPageUri={inboxPageUri}
              isLoggedIn={isUserLoggedIn}
              loading={proposalsLoading}
              locale={locale}
              noResultsMessage={t('investorDashboard.shortlist.description')}
              noResultsTitle={t('investorDashboard.shortlist.noShortlisted')}
              updateProposalList={(newList) => {
                return updateProposalsList(setProposals, newList);
              }}
            />
          )}
        </div>
      </div>

      {isModalOpen && (
        <GeneralModal
          title={modalTitle}
          description={modalDescription}
          closeModal={() => {
            setModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default InvestorDashboard;

InvestorDashboard.propTypes = {
  apiUrl: PropTypes.string,
  businessProposalUri: PropTypes.string.isRequired,
  connectionDailyConnectionUsage: PropTypes.string,
  currencyCode: PropTypes.string,
  inboxPageUri: PropTypes.string,
  investorMessageResponseRate: PropTypes.number,
  isUserLoggedIn: PropTypes.bool,
  locale: PropTypes.string,
  proposalsApiUrl: PropTypes.string,
  responseRateLevel: PropTypes.string,
  responseRateLevelBackgroundColor: PropTypes.string,
  responseRateLevelTextColor: PropTypes.string,
  connectionDailyConnectionUsagePercentage: PropTypes.number,
  unreadConnectionCount: PropTypes.number
};

InvestorDashboard.defaultProps = {
  apiUrl: '',
  connectionDailyConnectionUsage: '',
  currencyCode: '',
  inboxPageUri: '',
  investorMessageResponseRate: 0,
  isUserLoggedIn: false,
  locale: '',
  proposalsApiUrl: '',
  connectionDailyConnectionUsagePercentage: 0,
  unreadConnectionCount: 0,
  responseRateLevel: '',
  responseRateLevelBackgroundColor: '',
  responseRateLevelTextColor: ''
};
