/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../Util/i18n';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
  removeEntryFromArray,
  defaultUKLocationId,
  updateDropdownPlacement
} from '../../Util/data';
import Dropdown from '../../Components/Dropdown/Dropdown';
import InvestorCard from '../../Components/InvestorCard/InvestorCard';
import ListButton from '../../Components/Buttons/ListButton';
import LoadMore from '../../Components/Buttons/LoadMore';
import InvestorSkeleton from '../../Components/Card/InvestorSkeleton';
import TagsInput from '../../Components/TagsInput/TagsInput';
import {
  fetchInvestors,
  fetchIndustries,
  fetchStages,
  fetchPlaces,
  fetchQuickLinks,
  nudgeInvestor
} from '../../Util/ApiService';
import Modal from '../../Components/Modal/Modal';
import Pagination from '../../Components/Pagination/Pagination';

const FindInvestorsPage = ({
  isLoggedIn,
  quickLinks,
  locale,
  apiUrl,
  industriesUrl,
  stagesUrl,
  locationsUrl,
  currencyCode,
  maxInvestmentLimit,
  preFetchedResponse,
  pageHeader,
  pageDescription,
  lastPageNumber,
  userProposals,
  nudgeCount,
  businessProposalChooseYourPackageUri,
  createProposalUri,
  nudgePurchaseUri,
  userExpiredProposals,
  userInactiveProposals,
  searchBarQuery,
  defaultPageNumber,
  defaultPageSize,
  displayLoadMoreButton,
  investmentRange,
  orderByQueryParam,
  findInvestorsUrl
}) => {
  const { t } = useTranslation();

  const skeletonCards = Array(9).fill(<InvestorSkeleton />);

  // convert preFetchedresponse string to JSON
  const preRenderedData = JSON.parse(preFetchedResponse);
  const expiredProposals = JSON.parse(userExpiredProposals);
  const inactiveProposals = JSON.parse(userInactiveProposals);

  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [updatedPageNumber, setUpdatedPageNumber] = useState(1);
  const [totalResults, setTotalResults] = useState(
    preRenderedData.pagination.totalResults
  );
  const [investors, setInvestors] = useState(preRenderedData?.results);
  const [industries, setIndustries] = useState([]);
  const [stages, setStages] = useState([]);
  const [availableQuickLinks, setQuickLinks] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(
    displayLoadMoreButton
  );
  const [showNoResultsLabel, setShowNoResultsLabel] = useState(false);
  const [showIndustries, setShowIndustries] = useState(false);
  const [showStages, setShowStages] = useState(false);
  const [showQuickLinks, setShowQuickLinks] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [showContinents, setShowContinents] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [currentContinentId] = useState(0);
  const [countries, setCountries] = useState([]);
  const [continents, setContinents] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isTagsEmpty, setEmptyTags] = useState(false);

  const [checkedIndustries, setCheckedIndustries] = useState([]);
  const [checkedStages, setCheckedStages] = useState([]);
  const [checkedQuickLinks, setCheckedQuickLinks] = useState(
    new Array(quickLinks.length).fill(false)
  );
  const [checkedContinents, setCheckedContinents] = useState([]);
  const [checkedCountries, setCheckedCountries] = useState([]);
  const [enteredTags, setEnteredTags] = useState([]);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [checkedRange, setCheckedRange] = useState(null);
  const [isMyNudged, setMyNudged] = useState(false);
  const [isNudgeHidden, setNudgeHidden] = useState(false);
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [submittingNudge, setNudgeSubmitting] = useState(false);
  const [userAlreadyNudged, setAlreadyNudged] = useState(false);
  const [selectedNudgeUri, setSelectedNudgeUri] = useState('');
  const [additionalInput, setAdditionalInput] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalEventTarget, setModalEventTarget] = useState(null);
  const [modalButtonUri, setModalButtonUri] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [preEnteredTag, setPreEnteredTag] = useState(null);
  const [preEnteredPageNumber] = useState(defaultPageNumber);

  const ref = useOnclickOutside(() => {
    setShowQuickLinks(false);
  });

  const getInvestors = async (newPageNumber) => {
    setUpdatedPageNumber(newPageNumber);
    if (
      preEnteredPageNumber &&
      preEnteredPageNumber !== 1 &&
      !checkedContinents.length &&
      !checkedIndustries.length &&
      !checkedStages.length &&
      !checkedCountries.length &&
      !checkedLocations.length &&
      !isNudgeHidden &&
      !isMyNudged &&
      !checkedQuickLinks[1] &&
      pageNumber === newPageNumber &&
      !enteredTags.length &&
      !preEnteredTag
    ) {
      setInvestors(preRenderedData.results);
      const totalInvestors = preRenderedData.pagination.totalResults;
      setTotalResults(preRenderedData.pagination.totalResults);
      setPageNumber(defaultPageNumber);
      setShowLoadMoreButton(
        totalInvestors > defaultPageNumber * defaultPageSize
      );
      setLoading(false);
      setSearching(false);
      return;
    }
    if (
      !isLoggedIn &&
      !checkedContinents.length &&
      !checkedIndustries.length &&
      !checkedStages.length &&
      !checkedCountries.length &&
      !checkedLocations.length &&
      !isNudgeHidden &&
      !isMyNudged &&
      !checkedQuickLinks[1] &&
      pageNumber === newPageNumber &&
      newPageNumber === 1 &&
      !enteredTags.length &&
      !preEnteredTag
    ) {
      setInvestors(preRenderedData.results);
      const totalInvestors = preRenderedData.pagination.totalResults;
      setShowNoResultsLabel(!totalInvestors);
      setTotalResults(preRenderedData.pagination.totalResults);
      setPageNumber(newPageNumber);
      setShowLoadMoreButton(totalInvestors > newPageNumber * defaultPageSize);
      setLoading(false);
      return;
    }
    setLoading(true);
    const newInvestors = await fetchInvestors(apiUrl, {
      pageNumber: newPageNumber,
      industries: checkedIndustries,
      stages: checkedStages,
      countries: checkedCountries,
      locations: checkedLocations,
      myNudged: isMyNudged || null,
      myShortlisted: checkedQuickLinks[1] ? checkedQuickLinks[1] : null,
      hideNudged: isNudgeHidden || null,
      minInvestment: checkedRange || null,
      maxInvestment: checkedRange || null,
      searchQuery: enteredTags || searchBarQuery ? enteredTags : null,
      orderByOption: orderByQueryParam || null
    });
    // eslint-disable-next-line no-shadow
    const totalResults = Number(newInvestors.pagination.totalResults) || 0;
    setShowNoResultsLabel(!totalResults);
    setTotalResults(totalResults);
    // eslint-disable-next-line no-unused-expressions
    newPageNumber === 1
      ? setInvestors(newInvestors.results)
      : !isLoggedIn
      ? setInvestors(newInvestors.results)
      : setInvestors([...investors, ...newInvestors.results]);
    setPageNumber(newPageNumber);
    setShowLoadMoreButton(totalResults > newPageNumber * defaultPageSize);
    setLoading(false);
  };

  // get locations inside a country
  const getLocationsByCountry = async (id) => {
    const regions = await fetchPlaces(locationsUrl, {
      parentLocation: id || defaultUKLocationId
    });
    if (regions?.results) setLocations(regions.results);
  };

  // fetch quick links
  const getQuickLinks = async () => {
    const result = await fetchQuickLinks(quickLinks);
    const locationParam = result.find((object) => {
      return object.parameterName === 'countries';
    });
    // eslint-disable-next-line no-unused-expressions
    locationParam?.parameterValue
      ? getLocationsByCountry(locationParam.parameterValue)
      : getLocationsByCountry(defaultUKLocationId);
    setQuickLinks(result);
  };
  // get industries
  const getIndustries = async () => {
    const result = await fetchIndustries(industriesUrl);
    setIndustries(result);
  };
  // get stages
  const getStages = async () => {
    const result = await fetchStages(stagesUrl);
    setStages(result);
  };
  // get continent
  const getContinents = async () => {
    // eslint-disable-next-line no-shadow
    const continents = await fetchPlaces(locationsUrl, {
      topLocations: true
    });
    if (continents?.results) setContinents(continents.results);
  };

  const clearFilters = () => {
    setPageNumber(1);
    setUpdatedPageNumber(1);
    setCheckedIndustries([]);
    setCheckedStages([]);
    setCheckedQuickLinks(new Array(quickLinks.length).fill(false));
    setMyNudged(false);
    setNudgeHidden(false);
    setCheckedContinents([]);
    setCheckedCountries([]);
    setEnteredTags([]);
    setCheckedLocations([]);
    setCheckedRange(null);
    setPreEnteredTag(null);
    setEmptyTags(true);
    setTimeout(() => {
      setEmptyTags(false);
    }, 2000);
    setTotalResults(preRenderedData.pagination.totalResults);
    setInvestors(preRenderedData?.results);
    setShowLoadMoreButton(displayLoadMoreButton);
    setShowNoResultsLabel(false);
  };

  const clearSelection = (array, childArray, category) => {
    if (childArray) {
      setCheckedContinents([]);
      setCheckedCountries([]);
      return getInvestors(1);
    }

    // eslint-disable-next-line default-case
    switch (category) {
      case 'quickLinks':
        setCheckedQuickLinks(new Array(quickLinks.length).fill(false));
        setCheckedCountries([]);
        setMyNudged(false);
        setNudgeHidden(false);
        break;
      case 'industries':
        setCheckedIndustries([]);
        break;
      case 'locations':
        setCheckedLocations([]);
        break;
      case 'stages':
        setCheckedStages([]);
        break;
    }
    return getInvestors(1);
  };

  const clearRange = () => {
    setCheckedRange(null);
    getInvestors(1);
  };

  // eslint-disable-next-line consistent-return
  const updateCheckbox = (e, array, callbackFunc, isChecked, countriesList) => {
    if (!isChecked) {
      if (array === checkedContinents) {
        // eslint-disable-next-line array-callback-return
        countriesList.some((countryObject) => {
          const isFound = checkedCountries.some((country) => {
            return countryObject.locationId.toString().includes(country);
          });
          if (!isFound) {
            return;
          }
          const newArray = removeEntryFromArray(
            checkedCountries,
            checkedCountries.indexOf(countryObject.locationId.toString())
          );
          setCheckedCountries([...newArray]);
        });
      }
      const checkedArray = [...array];
      const indexOfElemToBeDeleted = array.indexOf(e.target.value);
      const newArray = removeEntryFromArray(
        checkedArray,
        indexOfElemToBeDeleted
      );
      return callbackFunc([...newArray]);
    }
    callbackFunc([...array, e.target.value]);
    if (array === checkedContinents) {
      // solution from https://stackoverflow.com/a/9229821
      setCheckedCountries([
        ...new Set([
          ...countriesList.map((country) => {
            return country.locationId.toString();
          }),
          ...checkedCountries
        ])
      ]);
    }
  };

  const handleContinentsChange = (array) => {
    setCheckedContinents(array);
  };

  const handleChange = (e, category, name, index, countriesList) => {
    const isChecked = e.target.checked;
    // eslint-disable-next-line default-case
    switch (category) {
      case 'industries':
        updateCheckbox(e, checkedIndustries, setCheckedIndustries, isChecked);
        break;
      case 'stages':
        updateCheckbox(e, checkedStages, setCheckedStages, isChecked);
        break;
      case 'continents':
        updateCheckbox(
          e,
          checkedContinents,
          handleContinentsChange,
          isChecked,
          countriesList
        );
        break;
      case 'locations':
        updateCheckbox(e, checkedLocations, setCheckedLocations, isChecked);
        break;
    }
  };

  const handleCountryChange = (e) => {
    updateCheckbox(e, checkedCountries, setCheckedCountries, e.target.checked);
    if (e.target.checked) {
      getLocationsByCountry(e.target.value);
    }
  };

  const updateCountries = (e) => {
    setCountries([...countries, ...e]);
  };

  const handleRange = (e) => {
    setCheckedRange(e);
  };

  // handle quick links change
  const handleQuickLinksChange = (event, pos, parameterName) => {
    const updatedCheckedState = checkedQuickLinks.map((item, index) => {
      return index === pos ? !item : item;
    });

    if (parameterName === 'countries' && event.target.checked) {
      setCheckedCountries([...checkedCountries, event.target.value]);
    }
    setCheckedQuickLinks(updatedCheckedState);

    if (parameterName === 'myNudged' && event.target.checked) {
      setMyNudged(true);
      setNudgeHidden(false);
      return;
    }
    if (parameterName === 'myNudged' && !event.target.checked) {
      setNudgeHidden(true);
      setMyNudged(false);
      return;
    }
    if (parameterName === 'hideNudged' && event.target.checked && isMyNudged) {
      setMyNudged(true);
      setNudgeHidden(false);
    } else if (
      parameterName === 'hideNudged' &&
      event.target.checked &&
      !isMyNudged
    ) {
      setMyNudged(false);
      setNudgeHidden(true);
    }
  };

  const updateCountriesList = (e) => {
    setAllCountriesList([...allCountriesList, ...e]);
  };

  const applyFilters = () => {
    setPageNumber(1);
    getInvestors(1);
  };

  const storeAdditionalInput = (input) => {
    if (!input.length || !input) {
      setAdditionalInput(null);
      const newTagsArray = enteredTags.pop();
      setEnteredTags([...newTagsArray]);
    }

    if (!enteredTags.length && !input.length) {
      setEnteredTags([]);
      setAdditionalInput(null);
    } else {
      setAdditionalInput(input);
    }
  };

  const searchWithQuery = async (query) => {
    setEnteredTags(query);
  };

  const searchWithTags = async () => {
    if (additionalInput) {
      setEnteredTags([...new Set([...enteredTags, additionalInput])]);
    }
    setSearching(true);
    setPageNumber(1);
    await getInvestors(1);
    setSearching(false);
  };

  // get investors on tags update
  useEffect(() => {
    if (enteredTags?.length) {
      getInvestors(1);
    }
  }, [enteredTags]);

  const openModal = (nudgeUri, title, modalTarget) => {
    setModalTitle(title);
    setModalEventTarget(modalTarget);
    // eslint-disable-next-line no-unused-expressions
    title === t('nudgeModal.sendMessage')
      ? setModalDescription(t('nudgeModal.tellWhy'))
      : setModalDescription('');
    if (title === t('investor.modal.clickBelowToRenew')) {
      setModalButtonText(t('auth.inbox.renew'));
      setModalButtonUri(businessProposalChooseYourPackageUri);
    } else if (title === t('investor.modal.clickBelowToUpgrade')) {
      setModalButtonText(t('nudgeModal.upgrade'));
      setModalButtonUri(businessProposalChooseYourPackageUri);
    } else {
      setModalButtonText(t('nudgeModal.addAPitch'));
      setModalButtonUri(createProposalUri);
    }
    setSelectedNudgeUri(nudgeUri);
    setModalOpen(true);
  };

  // nudge an investor
  const sendNudge = async (pitchId, message) => {
    setNudgeSubmitting(true);
    await nudgeInvestor(selectedNudgeUri, {
      proposal: pitchId,
      content: message
    });
    setAlreadyNudged(true);
    setTimeout(() => {
      setAlreadyNudged(false);
    }, 15);
    setNudgeSubmitting(false);
    setModalOpen(false);
  };

  useEffect(() => {
    if (searchBarQuery) {
      setPreEnteredTag(searchBarQuery);
      setEnteredTags([...enteredTags, searchBarQuery]);
    }
  }, [searchBarQuery]);

  const unsetPreRenderedTag = () => {
    setPreEnteredTag(null);
  };

  useEffect(() => {
    updateDropdownPlacement(showQuickLinks);
  }, [showQuickLinks]);

  useEffect(() => {
    if (isLoggedIn) {
      getQuickLinks();
      getIndustries();
      getStages();
      getContinents();
    }
  }, []);

  return (
    <>
      <div className="w-full flex flex-col items-center pt-8 min-h-screen bg-gray-50">
        <div className="w-full lg:max-w-screen-xl">
          {isLoggedIn && (
            <div className="w-full px-4 overflow-x-clip">
              <div
                className={`relative inline-block mr-3 mb-2 ${
                  showQuickLinks ? 'open' : ''
                }`}
              >
                <button
                  type="button"
                  ref={ref}
                  onClick={() => {
                    return showQuickLinks
                      ? setShowQuickLinks(false)
                      : setShowQuickLinks(true);
                  }}
                  className={`
                    bg-transparent
                    border
                    border-solid
                    border-gray-300
                    text-gray-700
                    px-5
                    py-2
                    text-sm
                    rounded-md
                    w-auto
                    hover:bg-gray-300
                    focus:bg-gray-200
                    whitespace-nowrap
                    transition-all
                  `}
                >
                  {t('findInvestors.quickLinks.title')}
                </button>
                <div
                  ref={ref}
                  className={`
                    absolute
                    transform
                    md:translate-x-0
                    transition-all
                    duration-300
                    py-6
                    px-5
                    bg-white
                    z-10
                    shadow-2xl
                    rounded-md
                    border
                    border-solid
                    border-gray-200
                    md:w-80
                    w-60
                    mt-4
                    h-auto
                    max-h-96
                    overflow-auto
                    react-dropdown-wrapper
                    ${showQuickLinks ? 'shown' : 'hidden'}
                  `}
                >
                  {availableQuickLinks.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-full items-center justify-between mb-3"
                      >
                        <div className="w-full">
                          <label
                            htmlFor={`quickLinks-${index}`}
                            className="flex items-center text-base text-gray-900 font-normal cursor-pointer"
                          >
                            <input
                              id={`quickLinks-${index}`}
                              type="checkbox"
                              name="quickLinks"
                              className="flex-initial h-5 w-5 border-gray-300 mr-1"
                              value={item.parameterValue}
                              onChange={(e) => {
                                return handleQuickLinksChange(
                                  e,
                                  index,
                                  item.parameterName
                                );
                              }}
                              checked={checkedQuickLinks[index]}
                            />
                            {item.caption}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                  <div className="mt-8 flex justify-between">
                    <button
                      type="button"
                      className="underline text-blue-500 text-base"
                      onClick={() => {
                        return clearSelection(
                          checkedQuickLinks,
                          null,
                          'quickLinks'
                        );
                      }}
                    >
                      {t('findInvestors.clear')}
                    </button>
                    <button
                      type="submit"
                      className="underline text-blue-500 text-base"
                      onClick={() => {
                        return applyFilters();
                      }}
                    >
                      {t('findInvestors.apply')}
                    </button>
                  </div>
                </div>
              </div>
              <Dropdown
                title={t('findInvestors.investmentRange')}
                showDropdown={showRange}
                category="invRange"
                selectedArray={checkedRange}
                locale={locale}
                onShowDropdown={() => {
                  return setShowRange(true);
                }}
                onHideDropdown={() => {
                  return setShowRange(false);
                }}
                applyFilters={() => {
                  return applyFilters();
                }}
                setValues={handleRange}
                hasChildren={false}
                clearFilters={() => {
                  return clearRange();
                }}
                currencyCode={currencyCode}
                maxInvestmentLimit={maxInvestmentLimit}
                hasInactiveProposals={inactiveProposals?.length > 0}
                hasExpiredProposals={expiredProposals?.length > 0}
                activeProposals={JSON.parse(userProposals)}
                openModal={openModal}
                isFindInvestors
                investmentRange={investmentRange}
              />
              <Dropdown
                title={t('findInvestors.country')}
                showDropdown={showContinents}
                category="continents"
                selectedArray={checkedContinents}
                selectedChildrenArray={checkedCountries}
                locale={locale}
                array={continents}
                onShowDropdown={() => {
                  return setShowContinents(true);
                }}
                onHideDropdown={() => {
                  return setShowContinents(false);
                }}
                applyFilters={() => {
                  return applyFilters();
                }}
                setValues={handleChange}
                setCountries={handleCountryChange}
                setCountriesArray={updateCountries}
                setAllCountries={updateCountriesList}
                hasChildren
                currentContinentId={currentContinentId}
                countries={countries}
                clearFilters={() => {
                  return clearSelection(
                    checkedContinents,
                    checkedCountries,
                    'continents'
                  );
                }}
                hasInactiveProposals={inactiveProposals?.length > 0}
                hasExpiredProposals={expiredProposals?.length > 0}
                activeProposals={JSON.parse(userProposals)}
                openModal={openModal}
                isFindInvestors
              />
              <Dropdown
                title={t('findInvestors.location')}
                showDropdown={showLocations}
                category="locations"
                selectedArray={checkedLocations}
                locale={locale}
                array={locations}
                onShowDropdown={() => {
                  return setShowLocations(true);
                }}
                onHideDropdown={() => {
                  return setShowLocations(false);
                }}
                applyFilters={() => {
                  return applyFilters();
                }}
                setValues={handleChange}
                hasChildren={false}
                clearFilters={() => {
                  return clearSelection(checkedLocations, null, 'locations');
                }}
                hasInactiveProposals={inactiveProposals?.length > 0}
                hasExpiredProposals={expiredProposals?.length > 0}
                activeProposals={JSON.parse(userProposals)}
                openModal={openModal}
                isFindInvestors
              />
              <Dropdown
                title={t('findInvestors.industry')}
                showDropdown={showIndustries}
                category="industries"
                selectedArray={checkedIndustries}
                locale={locale}
                array={industries}
                onShowDropdown={() => {
                  return setShowIndustries(true);
                }}
                onHideDropdown={() => {
                  return setShowIndustries(false);
                }}
                applyFilters={() => {
                  return applyFilters();
                }}
                setValues={handleChange}
                hasChildren={false}
                clearFilters={() => {
                  return clearSelection(checkedIndustries, null, 'industries');
                }}
                hasInactiveProposals={inactiveProposals?.length > 0}
                hasExpiredProposals={expiredProposals?.length > 0}
                activeProposals={JSON.parse(userProposals)}
                openModal={openModal}
                isFindInvestors
              />
              <Dropdown
                title={t('findInvestors.stage')}
                showDropdown={showStages}
                category="stages"
                selectedArray={checkedStages}
                locale={locale}
                array={stages}
                onShowDropdown={() => {
                  return setShowStages(true);
                }}
                onHideDropdown={() => {
                  return setShowStages(false);
                }}
                applyFilters={() => {
                  return applyFilters();
                }}
                setValues={handleChange}
                hasChildren={false}
                clearFilters={() => {
                  return clearSelection(checkedStages, null, 'stages');
                }}
                hasInactiveProposals={inactiveProposals?.length > 0}
                hasExpiredProposals={expiredProposals?.length > 0}
                activeProposals={JSON.parse(userProposals)}
                openModal={openModal}
                isFindInvestors
              />
              <ListButton
                title={t('findInvestors.clearFilters')}
                link
                onClick={clearFilters}
              />
            </div>
          )}

          <div className="w-full flex flex-wrap items-start md:gap-0 gap-y-3 px-4 my-4">
            <div className="md:w-3/4 w-full h-full">
              <TagsInput
                handleQueryInput={searchWithQuery}
                isEmpty={isTagsEmpty}
                preEnteredTags={preEnteredTag || null}
                unsetPreRenderedTag={unsetPreRenderedTag}
                storeInput={storeAdditionalInput}
              />
            </div>
            <div className="md:w-1/4 w-full md:px-3">
              <button
                type="submit"
                onClick={searchWithTags}
                className="bg-blue-400 text-white w-full flex items-center justify-center max-h-20 h-full px-4 py-3 rounded-md hover:bg-blue-500"
              >
                {searching ? (
                  <svg
                    className="animate-spin inline h-3.5 w-3.5 text-white fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <title>loading</title>
                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : (
                  t('findInvestors.apply')
                )}
              </button>
            </div>
          </div>
          <div className="w-full text-center px-4 my-5">
            {pageHeader && (
              <h1 className="font-medium text-gray-800 mb-3 pt-10 lg:text-4xl text-2xl">
                {pageHeader}
              </h1>
            )}
            {pageDescription && (
              <p className="text-base text-brand-lightgray">
                {pageDescription}
              </p>
            )}
          </div>
          <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            {isLoggedIn ? (
              updatedPageNumber === 1 ? (
                loading ? (
                  skeletonCards.map((card, i) => {
                    return <InvestorSkeleton key={`${card}-${i}`} />;
                  })
                ) : (
                  <>
                    {investors.map((investor) => {
                      return (
                        <InvestorCard
                          key={investor.detailsUrl}
                          investor={investor}
                          locale={locale}
                          nudgeUri={
                            isLoggedIn
                              ? investor?.investorUserActions?.nudgeInvestorUri
                              : false
                          }
                          shortlistUri={
                            isLoggedIn
                              ? investor?.investorUserActions
                                  ?.addShortlistedInvestorUri ||
                                investor?.investorUserActions
                                  ?.removeShortlistedInvestorUri
                              : false
                          }
                          openNudgeModal={openModal}
                          isLoggedIn={isLoggedIn}
                          alreadyNudged={userAlreadyNudged}
                          currencyCode={currencyCode}
                        />
                      );
                    })}
                  </>
                )
              ) : (
                <>
                  {investors.map((investor) => {
                    return (
                      <InvestorCard
                        key={investor.detailsUrl}
                        investor={investor}
                        locale={locale}
                        nudgeUri={
                          isLoggedIn
                            ? investor?.investorUserActions?.nudgeInvestorUri
                            : false
                        }
                        shortlistUri={
                          isLoggedIn
                            ? investor?.investorUserActions
                                ?.addShortlistedInvestorUri ||
                              investor?.investorUserActions
                                ?.removeShortlistedInvestorUri
                            : false
                        }
                        openNudgeModal={openModal}
                        isLoggedIn={isLoggedIn}
                        alreadyNudged={userAlreadyNudged}
                        currencyCode={currencyCode}
                      />
                    );
                  })}
                  {loading &&
                    skeletonCards.map((card, i) => {
                      return <InvestorSkeleton key={`${card}-${i}`} />;
                    })}
                </>
              )
            ) : loading ? (
              skeletonCards.map((card, i) => {
                return <InvestorSkeleton key={`${card}-${i}`} />;
              })
            ) : (
              investors.map((investor) => {
                return (
                  <InvestorCard
                    key={investor.detailsUrl}
                    investor={investor}
                    locale={locale}
                    nudgeUri={
                      isLoggedIn
                        ? investor?.investorUserActions?.nudgeInvestorUri
                        : false
                    }
                    shortlistUri={
                      isLoggedIn
                        ? investor?.investorUserActions
                            ?.addShortlistedInvestorUri ||
                          investor?.investorUserActions
                            ?.removeShortlistedInvestorUri
                        : false
                    }
                    openNudgeModal={openModal}
                    isLoggedIn={isLoggedIn}
                    alreadyNudged={userAlreadyNudged}
                    currencyCode={currencyCode}
                  />
                );
              })
            )}
          </div>
        </div>
        {showNoResultsLabel && (
          <div className="mt-8 mb-24">{t('investorCard.noInvestors')}</div>
        )}
        {isLoggedIn && showLoadMoreButton && (
          <div className="mt-8 mb-24">
            <LoadMore
              title={t('investorCard.button')}
              onClick={() => {
                return getInvestors(pageNumber + 1);
              }}
              loading={loading}
            />
          </div>
        )}
        {!isLoggedIn && (
          <div className="w-full lg:max-w-screen-xl mt-8 mb-24">
            <Pagination
              currentPage={pageNumber}
              totalResults={totalResults}
              pageSize={preRenderedData.pagination.pageSize}
              totalPages={lastPageNumber}
              paginationUrl={findInvestorsUrl}
            />
          </div>
        )}
      </div>
      {isModalOpen && (
        <Modal
          createProposalUri={createProposalUri}
          nudgePurchaseUri={nudgePurchaseUri}
          pitches={
            JSON.parse(userProposals)?.length ? JSON.parse(userProposals) : null
          }
          hasNudges={nudgeCount > 0}
          title={modalTitle}
          description={modalDescription}
          hideModal={() => {
            return setModalOpen(false);
          }}
          submitting={submittingNudge}
          submit={sendNudge}
          modalEventTarget={modalEventTarget}
          buttonText={modalButtonText}
          buttonUri={modalButtonUri}
        />
      )}
    </>
  );
};

export default FindInvestorsPage;

FindInvestorsPage.propTypes = {
  isLoggedIn: PropTypes.bool,
  quickLinks: PropTypes.string,
  locale: PropTypes.string,
  apiUrl: PropTypes.string,
  industriesUrl: PropTypes.string,
  stagesUrl: PropTypes.string,
  locationsUrl: PropTypes.string,
  currencyCode: PropTypes.string,
  maxInvestmentLimit: PropTypes.number,
  preFetchedResponse: PropTypes.string,
  lastPageNumber: PropTypes.number,
  userProposals: PropTypes.string,
  nudgeCount: PropTypes.number,
  businessProposalChooseYourPackageUri: PropTypes.string,
  createProposalUri: PropTypes.string,
  nudgePurchaseUri: PropTypes.string,
  userExpiredProposals: PropTypes.string,
  userInactiveProposals: PropTypes.string,
  searchBarQuery: PropTypes.string,
  defaultPageNumber: PropTypes.number,
  defaultPageSize: PropTypes.number,
  displayLoadMoreButton: PropTypes.bool.isRequired,
  pageHeader: PropTypes.string,
  pageDescription: PropTypes.string,
  investmentRange: PropTypes.instanceOf(Object),
  orderByQueryParam: PropTypes.oneOf([PropTypes.string, null]),
  findInvestorsUrl: PropTypes.string.isRequired
};

FindInvestorsPage.defaultProps = {
  isLoggedIn: false,
  quickLinks: '',
  locale: '',
  apiUrl: '',
  industriesUrl: '',
  stagesUrl: '',
  locationsUrl: '',
  currencyCode: '',
  maxInvestmentLimit: 0,
  preFetchedResponse: '',
  lastPageNumber: 0,
  userProposals: '',
  nudgeCount: 0,
  businessProposalChooseYourPackageUri: '',
  createProposalUri: '',
  nudgePurchaseUri: '',
  userExpiredProposals: '',
  userInactiveProposals: '',
  searchBarQuery: '',
  defaultPageNumber: 1,
  defaultPageSize: 0,
  pageHeader: '',
  pageDescription: '',
  investmentRange: null,
  orderByQueryParam: null
};
