/* eslint-disable indent */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import {
  deleteProposal,
  messageAction,
  shortlistProposal,
  unShortlistProposal
} from '../../Util/ApiService';
import DeleteButton from '../Buttons/Delete';
import HorizontalSeparator from '../Separators/Horizontal';
import ShortlistButton from '../Buttons/Shortlist';
import { roundUpInvestmentFigure } from '../../Util/data';
import TooltipAlt from '../Tooltip/TooltipAlt';
import Loader from '../Loader/Loader';

const ProposalSummary = ({
  proposal,
  deleteUri,
  shortlistUri,
  locale,
  removeProposalFromArray,
  cardOrientation,
  currencyCode,
  isMyListPage = false,
  isFromConnection = false,
  isEntrepreneurDashboard = false,
  isAffiliatePortfolio = false,
  inboxPageUri,
  isFromStaffPicks = false,
  isLoggedIn,
  openInfoModal,
  isInvestor,
  deletePitchFromDashboard,
  isAffiliatePrivatePortfolio
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const [shortlistLoading, setShortlistLoading] = useState(false);

  const {
    backgroundUri,
    completePercentage,
    countryName,
    description,
    locationName,
    logoUri,
    maxInvestment,
    milestones,
    minInvestment,
    proposalUri,
    proposalUserActions,
    title,
    exchangeRateToSiteCurrency
  } = proposal;

  const [isShortlisted, setIsShortlisted] = useState(
    !proposalUserActions?.addShortlistedProposalUri
  );

  const deletingProposal = async (url) => {
    setDeleting(true);
    await deleteProposal(url);
    setDeleting(false);
    removeProposalFromArray();
  };

  const deleteConnection = async (url) => {
    setDeleting(true);
    await messageAction(url).then(() => {
      setDeleting(false);
      removeProposalFromArray();
    });
  };

  const shortlistingProposal = async (url) => {
    setShortlistLoading(true);
    if (isShortlisted) {
      await unShortlistProposal(url).then(() => {
        if (isMyListPage) {
          removeProposalFromArray();
        }
      });
    } else {
      await shortlistProposal(url);
    }
    setIsShortlisted(!isShortlisted);
    setShortlistLoading(false);
  };

  return (
    <div
      className={`proposal ${
        cardOrientation === 'horizontal'
          ? 'flex md:flex-row flex-col'
          : 'md:max-w-xl flex-grow flex flex-col'
      } rounded-2xl bg-white text-left border border-blue-50 shadow-md`}
    >
      <div
        className={
          cardOrientation === 'horizontal' ? 'md:w-1/2 w-full' : 'w-full'
        }
      >
        <div
          className={`w-full ${
            cardOrientation === 'default'
              ? 'h-36 items-center'
              : 'md:h-full h-80 md:rounded-l-2xl md:rounded-tr-none'
          } bg-cover flex items-end relative group rounded-t-2xl overflow-hidden`}
          style={
            backgroundUri ? { backgroundImage: `url(${backgroundUri})` } : {}
          }
        >
          {cardOrientation === 'horizontal' ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          ) : isFromStaffPicks && isLoggedIn ? (
            <button
              type="button"
              className="absolute top-4 right-4 rounded-full group-hover:opacity-100 opacity-30 transition-all duration-500 w-8 h-8 bg-red-600 material-icons text-white text-sm"
              onClick={() => {
                return deletingProposal(deleteUri);
              }}
            >
              close
            </button>
          ) : isEntrepreneurDashboard ? (
            <span
              className={`
                block absolute capitalize tracking-normal text-center text-sm font-semibold -right-20 -top-3 transform rotate-45 pt-12 pb-1 w-52
                ${
                  // FIXME we need to think how handle it better, because we have duplicated functionality and hardwired values
                  proposal.subscriptionType.code === 'EX'
                    ? 'bg-purple-600 text-white'
                    : proposal.featured
                    ? 'bg-teal-600 text-white'
                    : proposal.subscriptionType.code === 'GP'
                    ? 'bg-yellow-600 text-white'
                    : proposal.subscriptionType.code === 'PR'
                    ? 'bg-blue-600 text-white'
                    : proposal.subscriptionType.code === 'NV'
                    ? 'bg-gray-600 text-white'
                    : 'bg-white text-red-700'
                }
              `}
            >
              {proposal.subscriptionType.code === 'NP' ||
              proposal.subscriptionType.code === null
                ? t('proposalsMacro.noPackage')
                : proposal.subscriptionType.name}
            </span>
          ) : proposal.subscriptionType.code === 'NP' ||
            proposal.subscriptionType.code === 'NV' ? null : (
            <span
              className={`
                block absolute capitalize tracking-normal text-white text-center text-sm font-semibold -right-20 -top-3 transform rotate-45 pt-12 pb-1 w-52
                ${
                  proposal.subscriptionType.code === 'EX'
                    ? 'bg-purple-600'
                    : proposal.featured
                    ? 'bg-teal-600'
                    : proposal.subscriptionType.code === 'GP'
                    ? 'bg-yellow-600'
                    : proposal.subscriptionType.code === 'PR'
                    ? 'bg-blue-600'
                    : ''
                }
              `}
            >
              {proposal.subscriptionType.code === 'EX'
                ? proposal.subscriptionType.name
                : proposal.featured
                ? t('proposalsMacro.featured')
                : proposal.subscriptionType.name}
            </span>
          )}
          <a href={proposalUri}>
            <img
              className={`
                w-24
                h-24
                object-cover
                object-center
                mx-6
                text-xs
                ${
                  cardOrientation === 'horizontal'
                    ? 'relative bottom-8 rounded-lg'
                    : 'rounded'
                }
              `}
              src={logoUri}
              alt={title}
            />
          </a>
        </div>
      </div>
      {cardOrientation !== 'horizontal' && !isAffiliatePortfolio && (
        <div
          className={`relative bg-gray-400 w-full h-2 group ${
            completePercentage > 0 ? 'cursor-pointer' : ''
          }`}
        >
          {completePercentage > 0 && (
            <>
              <div
                className="bg-green-400 h-full"
                style={{ width: `${completePercentage}%` }}
              />
              <TooltipAlt
                style={{ left: `${completePercentage}%` }}
                colorClass="green-400"
                textColor="white"
                label={`${completePercentage}% ${t('proposalsMacro.raised')}`}
              />
            </>
          )}
        </div>
      )}
      <div
        className={`p-6 flex flex-col h-full ${
          cardOrientation === 'horizontal' ? 'md:w-1/2' : ''
        }`}
      >
        <div>
          <h3 className="mb-0 text-brand-titledark text-xl font-semibold hover:underline pb-1">
            <a href={proposalUri} target="_blank" rel="noreferrer">
              {title}
            </a>
          </h3>
          {isEntrepreneurDashboard &&
            (proposal.active ? (
              <div className="flex items-center gap-1 py-1 text-sm font-semibold text-green-700">
                <span className="material-icons text-md">check_circle</span>
                {t('entrepreneurDashboard.proposalActive')}
              </div>
            ) : proposal.draft ? (
              <div className="flex items-center gap-1 py-1 text-sm font-semibold text-blue-700">
                <span className="material-icons text-md">info</span>
                {t('entrepreneurDashboard.pitchInDraft')}
              </div>
            ) : proposal.expired ? (
              <div className="flex items-center gap-1 py-1 text-sm font-semibold text-yellow-700">
                <span className="material-icons text-md">error</span>
                {t('entrepreneurDashboard.proposalNotActive')}
              </div>
            ) : (
              <div className="flex items-center gap-1 py-1 text-sm font-semibold text-red-700">
                <span className="material-icons text-md">error</span>
                {t('entrepreneurDashboard.packageNotSelected')}
              </div>
            ))}
          {!isEntrepreneurDashboard && (
            <p className="text-sm flex items-center">
              <span className="material-icons text-lg relative bottom-1 mr-1 text-blue-400">
                location_on
              </span>
              {locationName && countryName
                ? `${locationName}, ${countryName}`
                : locationName || countryName}
            </p>
          )}
        </div>
        <div className="py-3">
          {description && (
            <p className="text-brand-lightgray text-base leading-7">
              {description}
            </p>
          )}
        </div>
        {!isEntrepreneurDashboard && !isAffiliatePortfolio && (
          <ul className="list-disc text-base py-4 font-semibold text-gray-900 leading-relaxed flex-1">
            {milestones.slice(0, 3).map((milestone, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index} className="ml-4">
                  {milestone}
                </li>
              );
            })}
          </ul>
        )}

        <div
          className={`flex ${
            isEntrepreneurDashboard || isAffiliatePortfolio ? 'mt-auto' : ''
          } ${
            cardOrientation === 'default'
              ? 'flex-row'
              : 'flex-wrap justify-between'
          }`}
        >
          {cardOrientation === 'default' ? (
            <>
              <div className="w-1/2">
                <h4 className="mb-0 text-lg font-semibold">
                  {t('currency', {
                    val: roundUpInvestmentFigure(
                      maxInvestment * exchangeRateToSiteCurrency
                    ),
                    formatParams: {
                      val: {
                        currency: currencyCode,
                        locale: locale.replace(/_/g, '-'),
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </h4>
                <span className="text-sm">
                  {t('proposalsMacro.totalRequired')}
                </span>
              </div>
              <div className="w-1/2">
                <h4 className="mb-0 text-lg font-semibold">
                  {t('currency', {
                    val: roundUpInvestmentFigure(
                      minInvestment * exchangeRateToSiteCurrency
                    ),
                    formatParams: {
                      val: {
                        currency: currencyCode,
                        locale: locale.replace(/_/g, '-'),
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </h4>
                <span className="text-sm">
                  {t('proposalsMacro.minPerInvestor')}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="xl:w-1/4 w-1/2 px-2">
                <h4 className="mb-0 text-lg font-semibold">
                  {t('currency', {
                    val: roundUpInvestmentFigure(
                      minInvestment * exchangeRateToSiteCurrency
                    ),
                    formatParams: {
                      val: {
                        currency: currencyCode,
                        locale: locale.replace(/_/g, '-'),
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </h4>
                <span className="text-sm">
                  {t('proposalsMacro.minPerInvestor')}
                </span>
              </div>
              <div className="xl:w-1/4 w-1/2 px-2">
                <h4 className="mb-0 text-lg font-semibold">
                  {t('currency', {
                    val: roundUpInvestmentFigure(
                      maxInvestment * exchangeRateToSiteCurrency
                    ),
                    formatParams: {
                      val: {
                        currency: currencyCode,
                        locale: locale.replace(/_/g, '-'),
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </h4>
                <span className="text-sm">
                  {t('proposalsMacro.totalRequired')}
                </span>
              </div>
              <div className="xl:w-1/4 w-1/2 px-2">
                <h4 className="mb-0 text-lg font-semibold">
                  {completePercentage}%
                </h4>
                <span className="text-sm">{t('proposalsMacro.raised')}</span>
              </div>
              {proposal?.expiredDate && (
                <div className="xl:w-1/4 w-1/2 px-2">
                  <h4 className="mb-0 text-lg font-semibold">
                    {moment(proposal?.expiredDate).diff(
                      moment(new Date()),
                      'days'
                    ) < 1
                      ? 0
                      : moment(proposal?.expiredDate).diff(
                          moment(new Date()),
                          'days'
                        )}
                  </h4>
                  <span className="text-sm">
                    {moment(proposal?.expiredDate).diff(
                      moment(new Date()),
                      'days'
                    ) === 1
                      ? t('proposalsMacro.dayLeft')
                      : t('proposalsMacro.daysLeft')}
                  </span>
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex flex-row items-center mt-5">
          {isEntrepreneurDashboard ? (
            <>
              <a
                href={proposalUserActions.proposalDashboardUri}
                className="
                  bg-blue-600
                  text-white
                  px-3
                  py-1
                  rounded-md
                  w-auto
                  hover:bg-blue-500
                  focus:bg-blue-600
                  mr-2
                  text-sm
                  font-semibold
                  inline-flex
                  items-center
                  gap-2
                  leading-6
                "
              >
                {t('entrepreneurDashboard.manageMyPitch')}
              </a>
              <button
                type="button"
                onClick={() => {
                  return deletePitchFromDashboard(proposal.proposalId);
                }}
                className="bg-gray-200 text-gray-800 font-semibold rounded-md px-2 py-1 leading-6 inline-flex items-center gap-2"
              >
                <span className="material-icons text-gray-700 text-xl -mt-px">
                  delete
                </span>
              </button>
            </>
          ) : isAffiliatePortfolio ? (
            <>
              <a
                href={proposalUri}
                target="_blank"
                className="
                bg-blue-600
                text-white
                px-3
                py-1.5
                rounded-md
                w-auto
                hover:bg-blue-500
                focus:bg-blue-600
                mr-2
                text-sm
                font-semibold
                -mt-0.5
              "
                rel="noreferrer"
              >
                {t('proposalsMacro.button')}
              </a>
              {isAffiliatePrivatePortfolio && (
                <button
                  type="button"
                  onClick={() => {
                    return deletePitchFromDashboard(proposal.proposalId);
                  }}
                  className="bg-gray-200 text-gray-800 font-semibold rounded-md px-2 py-1 leading-6 inline-flex items-center gap-2"
                >
                  <span className="material-icons text-gray-700 text-xl -mt-px">
                    delete
                  </span>
                </button>
              )}
            </>
          ) : isFromConnection ? (
            <>
              <a
                href={inboxPageUri}
                className="
                  bg-blue-600
                  text-white
                  px-3
                  py-1
                  rounded-md
                  w-auto
                  hover:bg-blue-500
                  focus:bg-blue-600
                  mr-2
                  text-sm
                  font-semibold
                  inline-flex
                  items-center
                  gap-2
                  leading-6
                "
              >
                <span>{t('investorDashboard.message')}</span>
              </a>
              <button
                disabled={deleting}
                type="button"
                className="text-sm bg-gray-200 text-gray-800 font-semibold rounded-md px-2 py-1 leading-6 inline-flex items-center gap-2"
                onClick={() => {
                  return deleteConnection(deleteUri);
                }}
              >
                {deleting ? (
                  <Loader
                    customColor="text-gray-700"
                    width="w-5"
                    height="h-6"
                    title="deleting"
                  />
                ) : (
                  <span>{t('investorDashboard.notInterested')}</span>
                )}
              </button>
            </>
          ) : isFromStaffPicks && isLoggedIn && isInvestor ? (
            <button
              type="button"
              className="
                bg-blue-600
                text-white
                px-3
                py-1.5
                rounded-md
                w-full
                hover:bg-blue-500
                focus:bg-blue-600
                mr-2
                text-sm
                font-semibold
              "
              onClick={() => {
                return openInfoModal(proposal.proposalId);
              }}
            >
              {t('proposalsMacro.moreInfo')}
            </button>
          ) : (isFromStaffPicks && !isLoggedIn) ||
            (isFromStaffPicks && !isInvestor) ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          ) : (
            <>
              <a
                href={proposalUri}
                target="_blank"
                className="
                  bg-blue-600
                  text-white
                  px-3
                  py-1.5
                  rounded-md
                  w-auto
                  hover:bg-blue-500
                  focus:bg-blue-600
                  mr-2
                  text-sm
                  font-semibold
                  -mt-0.5
                "
                rel="noreferrer"
              >
                {t('proposalsMacro.button')}
              </a>
              {shortlistUri && (
                <>
                  <ShortlistButton
                    onClick={() => {
                      return shortlistingProposal(shortlistUri);
                    }}
                    loading={shortlistLoading}
                    isShortlisted={isShortlisted}
                  />
                  <HorizontalSeparator />
                </>
              )}
              {deleteUri && (
                <DeleteButton
                  onClick={() => {
                    return deletingProposal(deleteUri);
                  }}
                  deleting={deleting}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProposalSummary;

ProposalSummary.propTypes = {
  proposal: PropTypes.instanceOf(Object),
  deleteUri: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  shortlistUri: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  locale: PropTypes.string,
  removeProposalFromArray: PropTypes.func,
  cardOrientation: PropTypes.oneOf(['default', 'horizontal']),
  currencyCode: PropTypes.string,
  isMyListPage: PropTypes.bool,
  isFromConnection: PropTypes.bool,
  isEntrepreneurDashboard: PropTypes.bool,
  isAffiliatePortfolio: PropTypes.bool,
  inboxPageUri: PropTypes.string,
  isFromStaffPicks: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  openInfoModal: PropTypes.func,
  isInvestor: PropTypes.bool,
  deletePitchFromDashboard: PropTypes.func,
  isAffiliatePrivatePortfolio: PropTypes.bool
};

ProposalSummary.defaultProps = {
  proposal: {},
  deleteUri: '',
  shortlistUri: '',
  locale: '',
  removeProposalFromArray: () => {},
  cardOrientation: 'default',
  currencyCode: '',
  isMyListPage: false,
  isFromConnection: false,
  isEntrepreneurDashboard: false,
  isAffiliatePortfolio: false,
  inboxPageUri: '',
  isFromStaffPicks: false,
  isLoggedIn: false,
  openInfoModal: () => {},
  isInvestor: false,
  deletePitchFromDashboard: () => {},
  isAffiliatePrivatePortfolio: false
};
