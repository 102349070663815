import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProposalSummary from '../../Components/ProposalSummary/ProposalSummary';
import DashboardBottomSheet from '../../Components/Dashboard/DashboardBottomSheet';
import { removeEntryFromArray } from '../../Util/data';
import { deleteProposal, fetchProposals } from '../../Util/ApiService';
import LoadMore from '../../Components/Buttons/LoadMore';

const AffiliatePortfolio = ({
  currencyCode,
  deleteProposalUri,
  locale,
  preFetchedResponse,
  isPrivate,
  affiliatePartnerId,
  apiUrl
}) => {
  const { t } = useTranslation();

  const defaultPageSize = 6;
  const preRenderedData = JSON.parse(preFetchedResponse);
  const [proposals, setProposals] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResults, setTotalResults] = useState(
    preRenderedData.pagination.totalResults
  );
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(
    totalResults > pageNumber * defaultPageSize
  );
  const [loading, setLoading] = useState(false);
  const [showNoResultsLabel, setShowNoResultsLabel] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [selectedProposalIdForDeletion, setProposalIdForDeletion] =
    useState(null);

  const handleConfirmDeleteProposal = async () => {
    await deleteProposal(
      deleteProposalUri.replace(
        'PROPOSAL_ID',
        selectedProposalIdForDeletion.toString()
      )
    ).then(() => {
      const selectedProposal = proposals.find((proposal) => {
        return proposal.proposalId === selectedProposalIdForDeletion;
      });
      const selectedProposalIndex = proposals.indexOf(selectedProposal);
      const proposalsArray = [...proposals];
      const newArray = removeEntryFromArray(
        proposalsArray,
        selectedProposalIndex
      );
      setProposals([...newArray]);
      setTotalResults(totalResults - 1);
      setDisplayOverlay(false);
    });
  };

  const getProposals = async (newPageNumber) => {
    if (
      preRenderedData &&
      preRenderedData.pagination.totalResults &&
      pageNumber === newPageNumber &&
      newPageNumber === 1
    ) {
      setProposals(preRenderedData.results);
      const totalProposals = preRenderedData.pagination.totalResults;
      setTotalResults(totalProposals);
      setPageNumber(newPageNumber);
      setShowLoadMoreButton(totalProposals > newPageNumber * defaultPageSize);
      return;
    }
    setLoading(true);
    const newProposals = await fetchProposals(apiUrl, {
      affiliatePartner: affiliatePartnerId,
      pageNumber: newPageNumber,
      pageSize: defaultPageSize
    });
    const totalProposals = Number(newProposals.pagination.totalResults) || 0;
    setShowNoResultsLabel(!totalProposals);
    setTotalResults(totalProposals);
    // eslint-disable-next-line no-unused-expressions
    newPageNumber === 1
      ? setProposals(newProposals.results)
      : setProposals([...proposals, ...newProposals.results]);
    setPageNumber(newPageNumber);
    setShowLoadMoreButton(totalProposals > newPageNumber * defaultPageSize);
    setLoading(false);
  };

  const loadMoreProposals = () => {
    setPageNumber(pageNumber + 1);
    getProposals(pageNumber + 1);
  };

  const deletePitch = (proposalId) => {
    setProposalIdForDeletion(proposalId);
    setDisplayOverlay(true);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    getProposals(1);
  }, []);

  return (
    <>
      <div className="flex md:flex-row flex-col-reverse justify-between items-center mt-16">
        {totalResults} {t('portfolio.businessesFound')}
      </div>
      <div className="w-full flex flex-col items-center pt-8">
        <div className="w-full lg:max-w-screen-xl">
          <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 p-4">
            {proposals?.map((proposal) => {
              return (
                <ProposalSummary
                  key={proposal.proposalUri}
                  proposal={proposal}
                  locale={locale}
                  currencyCode={currencyCode}
                  isAffiliatePortfolio
                  deletePitchFromDashboard={(proposalId) => {
                    return deletePitch(proposalId);
                  }}
                  isAffiliatePrivatePortfolio={isPrivate}
                />
              );
            })}
          </div>
        </div>
        {showNoResultsLabel && (
          <div className="mt-8 mb-24">{t('portfolio.noProposals')}</div>
        )}
        {showLoadMoreButton && (
          <div className="mt-8 mb-24">
            <LoadMore
              title={t('portfolio.button')}
              onClick={loadMoreProposals}
              loading={loading}
            />
          </div>
        )}
        {displayOverlay && (
          <DashboardBottomSheet
            title={t('portfolio.modals.deleteProposal.title')} // FIXME we need translation for this
            description=""
            confirmButtonText={t('portfolio.modals.deleteProposal.button1')}
            discardButtonText={t('portfolio.modals.deleteProposal.button2')}
            handleConfirmButtonClick={() => {
              return handleConfirmDeleteProposal();
            }}
            hideModal={() => {
              return setDisplayOverlay(false);
            }}
            modalType=""
          />
        )}
      </div>
    </>
  );
};

export default AffiliatePortfolio;

AffiliatePortfolio.propTypes = {
  currencyCode: PropTypes.string,
  deleteProposalUri: PropTypes.string.isRequired,
  locale: PropTypes.string,
  preFetchedResponse: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  affiliatePartnerId: PropTypes.number.isRequired,
  apiUrl: PropTypes.string
};

AffiliatePortfolio.defaultProps = {
  currencyCode: '',
  locale: '',
  isPrivate: false,
  apiUrl: ''
};
